import classNames from 'classnames'

type TableProps = {
  headers: { key: string; label: string; width?: number }[]
  data: any[]
  actions?: { label: string | JSX.Element; onPress: (item: any) => void }[]
  onClick?: (item: any) => void
  renderItem?: (item: any) => any
}

export const Table: React.FC<TableProps> = ({ headers, data, actions, onClick, renderItem }) => {
  const handleItemClick = (item: any) => {
    onClick && onClick(item)
  }

  return (
    <div className="overflow-hidden sm:rounded-lg overflow-x-auto">
      <table className="table-auto w-full">
        <thead className="p-4 border-b-2 border-gray-200">
          <tr className="text-green text-left">
            {headers?.map((header, index) => (
              <th key={index} style={{ width: header?.width || 'auto' }} scope="col" className="p-4 font-semibold">
                {header.label}
              </th>
            ))}
            {actions && <th scope="col" className="p-4 font-semibold justify-end items-center flex"></th>}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((item, itemIndex) => (
              <tr key={itemIndex} className="border-b border-gray-50 p-4">
                {headers.map((header, headerIndex) => (
                  <td
                    key={headerIndex}
                    className={classNames('p-4', { 'cursor-pointer': !!onClick })}
                    onClick={() => handleItemClick(item)}
                  >
                    {renderItem ? renderItem(item)[header.key] : item[header.key]}
                  </td>
                ))}
                {actions?.length && (
                  <td className="flex flex-row justify-end items-center p-4">
                    {actions?.map((action, index) => (
                      <button
                        className="button button--light button--small mr-2"
                        disabled={action.onPress === undefined}
                        key={index}
                        onClick={() => action.onPress(item)}
                      >
                        {action.label}
                      </button>
                    ))}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length} className="p-4">
                -
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
