import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaCashRegister } from 'react-icons/fa'

import { useSales } from 'hooks'
import { Breadcrumbs, Pagination, Table } from 'components'
import { SaleHelper } from 'helpers'

export const SalesPage = () => {
  const history = useHistory()
  const { sales, getAllSales, deleteSaleById } = useSales()

  const handleDeleteItem = (id: string) => {
    deleteSaleById(id).then(() => getAllSales())
  }

  useEffect(() => {
    getAllSales()
  }, [getAllSales])

  return (
    <div className="p-8">
      <Breadcrumbs links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Vendas' }]} />

      <div className="box p-8 w-full">
        <h1 className="mb-8 flex items-center">
          <FaCashRegister color="#460edb" size="1.4rem" className="mr-2" />
          Vendas
        </h1>

        <div className="w-full flex justify-end content-around">
          <Link className="button button--primary" to="/vendas/adicionar">
            Adicionar
          </Link>
        </div>

        <Table
          headers={[
            { key: 'fantasyName', label: 'Empresa' },
            { key: 'value', label: 'Valor', width: 150 },
            { key: 'createdAt', label: 'Cadastro', width: 150 },
          ]}
          renderItem={(item) => ({
            ...item,
            fantasyName: item.budget?.customer?.fantasyName || item.budget?.customer?.contactName || '',
            createdAt: SaleHelper.formatCreatedAt(item.createdAt),
          })}
          actions={[{ label: 'Excluir', onPress: (item) => handleDeleteItem(item.id) }]}
          onClick={(item) => history.push(`/vendas/alterar/${item.id}`)}
          data={sales.items}
        />

        <Pagination currentPage={sales.currentPage} totalPages={sales.totalPages} />
      </div>
    </div>
  )
}
