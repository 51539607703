import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const email = yup.string().email('Informe um e-mail válido').required('Preencha seu e-mail')

const SigInSchema = yup
  .object({
    email,
    password: yup.string().max(255, 'Limite de 255 caracteres atigindo').required('Preencha sua senha'),
  })
  .required()
export const SigInResolver = yupResolver(SigInSchema)

const SigUpUserSchema = yup
  .object({
    firstName: yup.string().required('Preencha seu nome'),
    lastName: yup.string().required('Preencha seu sobrenome'),
    email,
    password: yup.string().max(255, 'Limite de 255 caracteres atigindo').required('Preencha sua senha'),
    confirmPassword: yup
      .string()
      .max(255, 'Limite de 255 caracteres atigindo')
      .required('Preencha sua senha')
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  })
  .required()
export const SigUpUserResolver = yupResolver(SigUpUserSchema)

const ForgotPasswordSchema = yup.object({ email }).required()
export const ForgotPasswordResolver = yupResolver(ForgotPasswordSchema)
