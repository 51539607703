import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { SignInPage, SignUpPage, ForgotPassword } from 'pages'

export const UnauthenticatedRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/entrar" component={SignInPage} />
      <Route exact path="/cadastrar" component={SignUpPage} />
      <Route exact path="/recuperar-senha" component={ForgotPassword} />

      <Redirect path="*" to="/entrar" />
    </Switch>
  </BrowserRouter>
)
