import ReactDatePicker from 'react-datepicker'
import { useController } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'
import get from 'lodash.get'

type DatePickerProps = {
  control: any
  name: string
  label?: string
  placeholder?: string
  showLabel?: boolean
  AppendComponent?: React.ReactNode
}

export const DatePicker: React.FC<DatePickerProps> = ({
  control,
  name,
  label,
  placeholder = 'Data',
  showLabel = true,
  AppendComponent,
}) => {
  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({ control, name })

  const errorMessage = get(errors, `${name}.message`)

  return (
    <div className="block w-full">
      {showLabel && (
        <label htmlFor={name} className="input-label">
          {label || placeholder}
        </label>
      )}
      <div className="flex flex-wrap">
        <ReactDatePicker
          locale={ptBR}
          dateFormat="P"
          selected={value}
          onChange={(date) => onChange(date)}
          placeholderText={placeholder}
          className="input"
        />
        {AppendComponent && AppendComponent}
      </div>
      {errorMessage && <p className=" block pt-2 input-text-error">{errorMessage}</p>}
    </div>
  )
}
