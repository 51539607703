import { FaTimes } from 'react-icons/fa'
import { Animated } from 'react-animated-css'

type ModalProps = {
  isVisible?: boolean
  setIsVisible: (isVisible: boolean) => void
  title: string
  onSave?: () => void
}

export const Modal: React.FC<ModalProps> = ({ children, title, isVisible = false, setIsVisible, onSave }) => {
  const closeModal = () => {
    setIsVisible(false)
  }

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={250}
      animationOutDuration={250}
      isVisible={isVisible}
      animateOnMount={false}
      className="w-full center fixed inset-0 bg-black bg-opacity-25 z-20"
    >
      <div className="block w-full max-w-lg bg-white p-8 rounded-md filter drop-shadow-lg">
        <div className="flex items-center justify-between">
          <h1 className="flex-1">{title}</h1>
          <button type="button" className="button button--small button--light" onClick={closeModal}>
            <FaTimes />
          </button>
        </div>

        <div className="w-full block py-8">{children}</div>

        <div className="flex justify-end">
          <button type="button" className="button button--primary" onClick={onSave}>
            Salvar
          </button>
        </div>
      </div>
    </Animated>
  )
}
