import { DateTime } from 'luxon'

const periodOptions = [
  { label: 'Últimos 7 dias', value: 'last_7' },
  { label: 'Últimos 14 dias', value: 'last_14' },
  { label: 'Últimos 30 dias', value: 'last_30' },
]

const getDateFromPeriod = (period: string) => {
  let createdAtGte, createdAtLte

  switch (period) {
    case 'year':
      createdAtGte = DateTime.now().startOf('year').toFormat('yyyy-MM-dd')
      createdAtLte = DateTime.now().toFormat('yyyy-MM-dd')
      break
    case 'last_30':
      createdAtGte = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
      createdAtLte = DateTime.now().toFormat('yyyy-MM-dd')
      break
    case 'last_14':
      createdAtGte = DateTime.now().minus({ days: 14 }).toFormat('yyyy-MM-dd')
      createdAtLte = DateTime.now().toFormat('yyyy-MM-dd')
      break
    case 'last_7':
      createdAtGte = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
      createdAtLte = DateTime.now().toFormat('yyyy-MM-dd')
      break
  }

  return {
    createdAtGte,
    createdAtLte,
  }
}

const getLastDays = (minus: number) => {
  const today = DateTime.now()
  let lastDay = DateTime.now().minus({ days: minus })

  const days: string[] = []
  while (!lastDay.equals(today)) {
    lastDay = lastDay.plus({ days: 1 })

    days.push(lastDay.toFormat('yyyy-MM-dd'))
  }

  return days
}

const getMonths = () => {
  const months: number[] = []
  for (let i = 1; i <= 12; i++) {
    months.push(i)
  }

  return months
}

const getMonthLabels = () => {
  return [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
}

export const DashboardHelper = {
  periodOptions,
  getDateFromPeriod,
  getLastDays,
  getMonths,
  getMonthLabels,
}
