import { AxiosResponse } from 'axios'

import { IBrasilAPICompany, ICustomer, IFilterCustomer, IPagination, IStoreCustomer, IUpdateCustomer } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterCustomer) => api.get<IPagination<ICustomer>>('/api/v1/customers', { params })

const getById = (id: string) => api.get<ICustomer>(`/api/v1/customers/${id}`)

const createNew = (customer: IStoreCustomer) =>
  api.post<IStoreCustomer, AxiosResponse<IBrasilAPICompany>>('/api/v1/customers', customer)

const updateById = (id: string, customer: IUpdateCustomer) =>
  api.put<IUpdateCustomer, AxiosResponse<IBrasilAPICompany>>(`/api/v1/customers/${id}`, customer)

const deleteById = (id: string) => api.delete(`/api/v1/customers/${id}`)

export const CustomersServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
}
