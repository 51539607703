import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { FaSearch } from 'react-icons/fa'

import { Breadcrumbs, Button, Input, RadioGroup, Tabs } from 'components'
import { useBrasilAPI, useCustomers } from 'hooks'
import { CreateCustomerResolver } from 'validations'

export const CustomersAddPage = () => {
  const [isCompanyLoading, setIsCompanyLoading] = useState(false)
  const [isZipcodeLoading, setIsZipcodeLoading] = useState(false)

  const history = useHistory()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    resolver: CreateCustomerResolver,
    defaultValues: { personType: 'JURIDICA', notificationEnabled: '1' },
  })
  const { address, company, getAddressByZipcodeDebounced, getCompanyInfoByCNPJDebounced } = useBrasilAPI()
  const { createNewCustomer } = useCustomers()

  const cnpjWatched = watch('cnpj')
  const zipcodeWatched = watch('zipcode')
  const personTypeWatched = watch('personType')

  const onSubmit = async (value: any) => {
    const response = await createNewCustomer(value)
    if (response) {
      history.push('/clientes')
    }
  }

  const searchCompanyAddressByZipcode = useCallback(() => {
    if (zipcodeWatched) {
      const zipcodeStripped = String(zipcodeWatched)
      if (zipcodeStripped?.length >= 8) {
        setIsZipcodeLoading(true)
        getAddressByZipcodeDebounced(String(zipcodeWatched))
        setTimeout(() => setIsZipcodeLoading(false), 5000)
      }
    }
  }, [getAddressByZipcodeDebounced, zipcodeWatched])

  const searchCompanyByCNPJ = useCallback(() => {
    if (cnpjWatched && cnpjWatched?.length >= 18) {
      setIsCompanyLoading(true)
      getCompanyInfoByCNPJDebounced(cnpjWatched)
      setTimeout(() => setIsCompanyLoading(false), 5000)
    }
  }, [getCompanyInfoByCNPJDebounced, cnpjWatched])

  useEffect(() => {
    if (address) {
      setValue('state', address.state)
      setValue('city', address.city)
      setValue('neighborhood', address.neighborhood)
      setValue('addressName', address.street)
    }
    setIsZipcodeLoading(false)
  }, [address, setValue])

  useEffect(() => {
    if (company) {
      setValue('socialName', company.razao_social)
      setValue('fantasyName', company.nome_fantasia || company.razao_social)
      setValue('zipcode', company.cep)

      let phone = company.ddd_fax || company.ddd_telefone_1 || company.ddd_telefone_2
      phone = String(phone).replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
      setValue('phone', phone)
    }
    setIsCompanyLoading(false)
  }, [company, setValue])

  useEffect(() => {
    if (personTypeWatched === 'FISICA') {
      setValue('cpf', '')
    } else {
      setValue('cnpj', '')
      setValue('socialName', '')
      setValue('fantasyName', '')
    }
  }, [personTypeWatched, setValue])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Clientes', href: '/clientes' },
          { label: 'Adicionar' },
        ]}
      />

      <div className="box p-8 w-full">
        <h1 className="mb-8">Adicionar Cliente</h1>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            header={['Dados', 'Contato', 'Endereço', 'Cobrança']}
            items={[
              {
                Component: (
                  <div className="my-6">
                    <RadioGroup
                      control={control}
                      name="personType"
                      label="Tipo"
                      items={[
                        { id: 'juridica', label: 'Pessoa Jurídica', value: 'JURIDICA' },
                        { id: 'fisica', label: 'Pessoa Física', value: 'FISICA' },
                      ]}
                    />

                    {personTypeWatched === 'JURIDICA' ? (
                      <>
                        <div className="sm:grid gap-2 grid-cols-2">
                          <Input
                            control={control}
                            name="cnpj"
                            placeholder="CNPJ"
                            mask="99.999.999/9999-99"
                            helperText="Pesquise a empresa pelo CNPJ"
                            AppendComponent={
                              <Button
                                isLoading={isCompanyLoading}
                                className="button--light lg:ml-2"
                                type="button"
                                onClick={searchCompanyByCNPJ}
                              >
                                <FaSearch />
                              </Button>
                            }
                          />
                        </div>
                        <div className="sm:grid gap-2 grid-cols-2">
                          <Input control={control} name="fantasyName" placeholder="Nome Fantasia" />
                          <Input control={control} name="socialName" placeholder="Razão Social" />
                        </div>
                      </>
                    ) : (
                      <div className="w-1/2 flex items-start mt-4">
                        <Input control={control} name="cpf" placeholder="CPF" mask="999.999.999-99" />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                Component: (
                  <div className="my-6">
                    <div className="w-full lg:w-1/2">
                      <Input control={control} name="contactName" placeholder="Nome completo" />
                    </div>

                    <div className="sm:grid gap-2 grid-cols-3">
                      <Input control={control} name="email" placeholder="E-mail" />
                      <Input control={control} name="cellphone" placeholder="Celular" mask="(99) 99999-9999" />
                      <Input control={control} name="phone" placeholder="Telefone" mask="(99) 9999-9999" />
                    </div>
                  </div>
                ),
              },
              {
                Component: (
                  <div className="my-4">
                    <div className="w-full lg:w-1/2 flex items-start">
                      <Input
                        control={control}
                        name="zipcode"
                        helperText="Pesquise o endereço pelo CEP"
                        placeholder="CEP"
                        mask="99999-999"
                        AppendComponent={
                          <Button
                            isLoading={isZipcodeLoading}
                            className="button--light lg:ml-2"
                            type="button"
                            onClick={searchCompanyAddressByZipcode}
                          >
                            <FaSearch />
                          </Button>
                        }
                      />
                    </div>

                    <div className="sm:grid gap-2 grid-cols-2">
                      <Input control={control} name="addressName" placeholder="Endereço" />
                      <Input control={control} name="addressNumber" placeholder="Número" />
                    </div>

                    <div className="sm:grid gap-2 grid-cols-3">
                      <Input control={control} name="neighborhood" placeholder="Bairro" />
                      <Input control={control} name="city" placeholder="Cidade" />
                      <Input control={control} name="state" placeholder="UF" />
                    </div>
                  </div>
                ),
              },
              {
                Component: (
                  <div className="my-4">
                    <div className="w-full lg:w-1/2 flex items-start">
                      <RadioGroup
                        control={control}
                        name="notificationEnabled"
                        label="Notificação habilitada"
                        items={[
                          { id: 'yes', label: 'Sim', value: '1' },
                          { id: 'no', label: 'Não', value: '0' },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
          />

          <div className="w-full flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
