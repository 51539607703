import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { DatePicker, Input, Modal, Select } from 'components'
import { NumberHelper, SaleInstallmentHelper } from 'helpers'
import { useSales } from 'hooks'
import { PaymentStatusEnum } from 'enum/payment-status.enum'
import { ISaleInstallment } from 'interfaces'
import { DateTime } from 'luxon'

type SaleInstallmentsStatusModalProps = {
  installment?: ISaleInstallment
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onSubmit: () => void
}

const DEFAULT_VALUE = SaleInstallmentHelper.selectOptions.find(({ value }) => value === PaymentStatusEnum.RECEIVED)

export const SaleInstallmentsStatusModal: React.FC<SaleInstallmentsStatusModalProps> = ({
  installment,
  isModalVisible,
  setIsModalVisible,
  onSubmit,
}) => {
  const { control, getValues, setValue } = useForm<{
    paidAt: Date
    status: { label: string; value: string }
    netValue: string
  }>({
    defaultValues: {
      paidAt: new Date(),
      status: DEFAULT_VALUE,
      netValue: installment?.netValue || '0',
    },
  })

  const { updateSaleInstallmentById } = useSales()

  const handleSubmit = async () => {
    if (!installment) return

    const formData = getValues()
    const payload = SaleInstallmentHelper.formToUpdatePayload(formData)
    await updateSaleInstallmentById(installment.id, payload)

    if (onSubmit) onSubmit()
  }

  useEffect(() => {
    if (installment) {
      setValue('paidAt', DateTime.fromFormat(installment.dueAt, 'dd/MM/yyyy').toJSDate())
      setValue('netValue', NumberHelper.formatToBRL(installment.netValue))
    }
  }, [installment, setValue])

  if (!isModalVisible) return null

  return (
    <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible} title="Atualizar parcela" onSave={handleSubmit}>
      <Select
        control={control}
        name="status"
        label="Status"
        options={SaleInstallmentHelper.selectOptions.filter(({ value }) =>
          [PaymentStatusEnum.RECEIVED, PaymentStatusEnum.RECEIVED_IN_CASH].includes(value)
        )}
      />
      <DatePicker control={control} name="paidAt" label="Data do Pagamento" />
      <Input control={control} name="netValue" label="Recebido" currency />
    </Modal>
  )
}
