import React, { useState, createContext, useContext, useEffect } from 'react'

const SidebarContext = createContext({
  isVisible: true,
  setIsVisible: (isVisible: boolean | ((isVisible: boolean) => boolean)) => {},
  isMaxWidthEnable: true,
  setIsMaxWidthEnable: (isMaxWidthEnable: boolean | ((isMaxWidthEnable: boolean) => boolean)) => {},
})

const useSidebarContext = () => useContext(SidebarContext)

const SidebarProvider: React.FC = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true)
  const [isMaxWidthEnable, setIsMaxWidthEnable] = useState(true)

  useEffect(() => {}, [])

  return (
    <SidebarContext.Provider value={{ isVisible, setIsVisible, isMaxWidthEnable, setIsMaxWidthEnable }}>
      {children}
    </SidebarContext.Provider>
  )
}

export { useSidebarContext, SidebarProvider }
