import { useController } from 'react-hook-form'
import ReactSelect from 'react-select'
import ReactAsyncSelect from 'react-select/async'
import get from 'lodash.get'
import { SelectHelper } from 'helpers'

type ISelect = { label: string; value: string | number }

type SelectProps = {
  async?: boolean
  loadOptions?: (inputValue: string, callback: (options: any) => void) => any
  defaultOptions?: { label: string; value: string }[]
  isLoading?: boolean
  control: any
  name: string
  placeholder?: string
  options?: ISelect[]
  defaultValue?: ISelect
  watch?: boolean
  onChange?: (data: any) => void
  label?: string
  showLabel?: boolean
  AppendComponent?: React.ReactNode
}

export const Select: React.FC<SelectProps> = ({
  async,
  loadOptions,
  defaultOptions,
  control,
  name,
  options,
  placeholder,
  defaultValue,
  label,
  showLabel = true,
  AppendComponent,
  onChange,
  isLoading,
}) => {
  const {
    field: { value, onChange: fieldOnChange },
    formState: { errors },
  } = useController({ control, name })

  const errorMessage = get(errors, `${name}.value.message`)

  const handleInputChange = (data: any) => {
    if (onChange) onChange(data)

    fieldOnChange(data)
  }

  return (
    <div className="block">
      {showLabel && (
        <label htmlFor={name} className="input-label">
          {label || placeholder}
        </label>
      )}
      <div className="flex flex-wrap">
        <div className="flex-1">
          {async ? (
            <ReactAsyncSelect
              id={name}
              name={name}
              cacheOptions
              value={value}
              defaultValue={defaultValue}
              defaultOptions={defaultOptions}
              loadOptions={loadOptions}
              isLoading={isLoading}
              onChange={handleInputChange}
              placeholder={placeholder || label}
              noOptionsMessage={({ inputValue }) =>
                inputValue ? `Opção (${inputValue}) não foi encontrada.` : 'Digite para pesquisar'
              }
              loadingMessage={() => `Carregando...`}
              styles={SelectHelper.styles}
            />
          ) : (
            <ReactSelect
              id={name}
              name={name}
              options={options}
              onChange={handleInputChange}
              defaultValue={defaultValue}
              value={value}
              placeholder={placeholder || label}
              noOptionsMessage={({ inputValue }) => `Opção (${inputValue}) não foi encontrada.`}
              loadingMessage={() => `Carregando...`}
              styles={SelectHelper.styles}
            />
          )}
        </div>
        {AppendComponent && AppendComponent}
      </div>
      {errorMessage && <p className="block pt-2 input-text-error">{errorMessage}</p>}
    </div>
  )
}
