import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateContractsSchema = yup
  .object({
    name: yup.string().max(255, 'Limite máximo de 255 caracteres atigindo').required('Informe o nome do contrato'),
    content: yup.string().required('Informe o conteúdo do contrato'),
  })
  .required()
export const CreateContractsResolver = yupResolver(CreateContractsSchema)
