import { useForm } from 'react-hook-form'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { Button, TextArea } from 'components'
import { CreateCartResolver } from 'validations'
import { IStoreCardForm } from 'interfaces'
import { useBoards } from 'hooks'

type NewCardFormProps = {
  boardSectionId: string
  onSave: () => void
  onClose: () => void
}

export const NewCardForm: React.FC<NewCardFormProps> = ({ boardSectionId, onSave, onClose }) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm({ resolver: CreateCartResolver })

  const { createNewCard } = useBoards()

  const onSubmit = async (values: IStoreCardForm) => {
    if (boardSectionId) {
      await createNewCard(boardSectionId, values)
      onSave && onSave()
      setValue('name', '')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <TextArea
        autoFocus
        control={control}
        name="name"
        showLabel={false}
        placeholder="Informe o título do card"
        rows={5}
      />
      <div className="flex">
        <Button type="submit" isLoading={isSubmitting} className="button--small button--full bg-primary text-white">
          <FaCheck color="#fff" className="mr-2" />
          Salvar
        </Button>
        <button type="button" className="p-2 ml-2" onClick={() => onClose()}>
          <FaTimes color="#666" />
        </button>
      </div>
    </form>
  )
}
