import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const select = (message: string) =>
  yup.object({
    label: yup.string().required(message),
    value: yup.string().required(message),
  })

const StoreBudgetSchema = yup.object({
  title: yup.string().required('Informe o título do orçamento'),
  dueAt: yup.date().nullable(),
  customerId: select('Selecione uma empresa').required(),
  value: yup
    .string()
    .test('no-zero', 'O valor deve ser maior que zero', (value) => (value ? parseFloat(value) > 0 : false))
    .required('Informe o valor'),
  goal: yup.string().nullable(),
  installments: yup.number().min(1, 'O mínimo de parcela é 1').nullable(),
  discount: yup.number().min(0, 'O mínimo é 0%').max(100, 'O máximo é 100%').nullable(),
  hours: yup.number().min(1, 'O valor deve ser maior que zero').nullable(),
  budgetServices: yup
    .array()
    .of(
      yup.object({
        serviceId: select('Selecione o serviço').required(),
        content: yup.string().nullable().required('Descreva o que será feito nesse serviço'),
      })
    )
    .required(),
})

export const StoreBudgetResolver = yupResolver(StoreBudgetSchema)

const UpdateBudgetSchema = yup.object({
  title: yup.string().required('Informe o título do orçamento'),
  dueAt: yup.date().nullable(),
  customerId: select('Selecione uma empresa').required(),
  value: yup
    .string()
    .test('no-zero', 'O valor deve ser maior que zero', (value) => (value ? parseFloat(value) > 0 : false))
    .required('Informe o valor'),
  goal: yup.string().nullable(),
  installments: yup.number().min(1, 'O mínimo de parcela é 1').nullable(),
  discount: yup.string().min(0, 'O mínimo é 0%').max(100, 'O máximo é 100%').nullable(),
  hours: yup.number().min(1, 'O valor deve ser maior que zero').nullable(),
  budgetServices: yup
    .array()
    .of(
      yup.object({
        id: yup.string().nullable(),
        budgetId: yup.string().nullable(),
        serviceId: select('Selecione o serviço').required(),
        content: yup.string().nullable().required('Descreva o que será feito nesse serviço'),
      })
    )
    .required(),
})

export const UpdateBudgetResolver = yupResolver(UpdateBudgetSchema)
