import axios from 'axios'
import { StorageHelper } from 'helpers'
import { toast } from 'react-toastify'

export const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3333'

export const api = axios.create({ baseURL })

if (window.localStorage) {
  if (window.localStorage.getItem('@erp/user')) {
    const user = JSON.parse(window.localStorage.getItem('@erp/user') || '')
    api.defaults.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user.token}`,
    }
  }
}

api.interceptors.response.use(
  function (config) {
    return config
  },
  function (error) {
    if (error?.response?.status === 401) {
      toast.error('Sessão expirada, por favor faça o login novamente!')
      StorageHelper.removeItem('@erp/user')
      window.location.href = '/entrar'
      return
    }

    const messages: string[] = error?.response?.data?.message

    if (messages) {
      if (Array.isArray(messages)) {
        if (messages.length > 0) {
          messages.map((message) => toast.error(message))
        }
      } else {
        toast.error(messages)
      }
    } else {
      toast.error('Houve um erro inesperado!')
    }

    return Promise.reject(error)
  }
)
