import { DateTime } from 'luxon'

import { IBoard } from 'interfaces'
import { IStoreBoardForm } from 'interfaces'

const formatToDisplay = (boards: IBoard[]) =>
  boards.map((board) => ({
    ...board,
    dueAt: DateTime.fromISO(board.dueAt).setLocale('pt-BR').toFormat('D'),
    createdAt: DateTime.fromISO(board.createdAt).setLocale('pt-BR').toFormat('D'),
  }))

const formToStorePayload = (form: IStoreBoardForm) => ({
  ...form,
  dueAt: DateTime.fromJSDate(new Date(form.dueAt)).toFormat('yyyy-MM-dd'),
  budgetId: form.budgetId.value,
})

export const BoardHelper = {
  formatToDisplay,
  formToStorePayload,
}
