import { useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { FaPlus, FaTimes } from 'react-icons/fa'

import { Breadcrumbs, Button, DatePicker, Input, Select, Tabs } from 'components'
import { useSales, useBudgets, useContracts } from 'hooks'
import { CreateSalesResolver } from 'validations'
import { BudgetHelper, SaleHelper } from 'helpers'
import { IStoreSaleForm } from 'interfaces'
import { PaymentMethodsConfig } from 'config'

export const SalesAddPage = () => {
  const history = useHistory()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: CreateSalesResolver,
    defaultValues: {
      budgetId: undefined,
      salePaymentMethods: [],
      saleContracts: [],
    },
  })
  const salePaymentMethods = useFieldArray({ control, name: `salePaymentMethods` })
  const saleContracts = useFieldArray({ control, name: 'saleContracts' as never })

  const { createNewSale } = useSales()
  const { budgets, getAllBudgets, getAllBudgetsOptionsDebounced } = useBudgets()
  const { contracts, getAllContracts } = useContracts()

  const onSubmit = async (value: IStoreSaleForm) => {
    const data = SaleHelper.formToStorePayload(value)
    const response = await createNewSale(data)
    if (response) {
      history.push('/vendas')
    }
  }

  useEffect(() => {
    Promise.all([getAllBudgets({ billed: 0 }), getAllContracts()])
  }, [getAllBudgets, getAllContracts])

  const budgetOptions = useMemo(() => BudgetHelper.formatToOptions(budgets?.items), [budgets?.items])

  const contractsOptions = useMemo(
    () => contracts?.items?.map(({ id, name }) => ({ label: name, value: id })),
    [contracts?.items]
  )

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Vendas', href: '/vendas' },
          { label: 'Adicionar' },
        ]}
      />

      <div className="box p-8 w-full">
        <h1 className="mb-8">Adicionar Venda</h1>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 w-full max-w-lg">
            <Select
              async
              defaultOptions={budgetOptions}
              loadOptions={(input, callback) => {
                getAllBudgetsOptionsDebounced(callback, { billed: 0, search: input })
              }}
              control={control}
              name="budgetId"
              placeholder="Orçamento"
              onChange={() => {
                salePaymentMethods.remove()
                salePaymentMethods.append({
                  dueAt: new Date(),
                  billingType: PaymentMethodsConfig.methodsList[0],
                  installment: 1,
                  value: '0',
                })
              }}
            />
          </div>

          <Tabs
            header={['Forma de Pagamento', 'Contratos']}
            items={[
              {
                Component: (
                  <div className="pt-2 my-6">
                    <button
                      type="button"
                      className="button button--small button--light"
                      onClick={() =>
                        salePaymentMethods.prepend({ billingType: undefined, installment: undefined, value: undefined })
                      }
                    >
                      <FaPlus className="mr-2" />
                      Adicionar forma de pagamento
                    </button>
                    {salePaymentMethods.fields.map((salePaymentMethodItem, salePaymentMethodIndex) => (
                      <div key={salePaymentMethodIndex} className="my-4 pb-4">
                        <div
                          key={salePaymentMethodItem.id}
                          className="w-full flex flex-col lg:flex-row flex-wrap items-center justify-between"
                        >
                          <div className="w-full lg:grid grid-cols-4 gap-2 mr-2">
                            <DatePicker
                              control={control}
                              name={`salePaymentMethods[${salePaymentMethodIndex}].dueAt`}
                              label="Data do Pagamento"
                            />
                            <Select
                              control={control}
                              options={PaymentMethodsConfig.methodsList}
                              name={`salePaymentMethods[${salePaymentMethodIndex}].billingType`}
                              label="Forma de Pagamento"
                              placeholder="Selecione"
                            />
                            <Input
                              control={control}
                              name={`salePaymentMethods[${salePaymentMethodIndex}].value`}
                              label="Valor total"
                              placeholder="997"
                              currency
                            />
                            <Input
                              type="number"
                              control={control}
                              name={`salePaymentMethods[${salePaymentMethodIndex}].installment`}
                              label="Parcelas"
                              placeholder="12"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="button button--small button--light"
                          onClick={() => salePaymentMethods.remove(salePaymentMethodIndex)}
                        >
                          <FaTimes />
                          <span className="ml-2">Remover</span>
                        </button>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                Component: (
                  <div className="pt-2 my-6">
                    <button
                      type="button"
                      className="button button--small button--light mb-4"
                      onClick={() => saleContracts.prepend({ contractId: undefined })}
                    >
                      <FaPlus className="mr-2" />
                      Adicionar contrato
                    </button>
                    {saleContracts.fields.map((saleContract, saleContractIndex) => (
                      <div
                        key={saleContract.id}
                        className="w-full mb-4 pb-4 flex flex-col flex-wrap items-start justify-center"
                      >
                        <div className="w-full mb-2">
                          <Select
                            control={control}
                            options={contractsOptions}
                            name={`saleContracts[${saleContractIndex}].contractId`}
                            placeholder="Contrato"
                          />
                        </div>

                        <button
                          type="button"
                          className="button button--small button--light"
                          onClick={() => saleContracts.remove(saleContractIndex)}
                        >
                          <FaTimes />
                          <span className="ml-2">Remover</span>
                        </button>
                      </div>
                    ))}
                  </div>
                ),
              },
            ]}
          />

          <div className="flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Cadastrar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
