import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import { IBudget, IFilterBudget, IPagination, IStoreBudgetForm, IUpdateBudgetForm } from 'interfaces'
import { BudgetServices } from 'services'
import { BudgetHelper } from 'helpers'

export const useBudgets = () => {
  const [budget, setBudget] = useState<IBudget>()
  const [budgets, setBudgets] = useState<IPagination<IBudget>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllBudgets = useCallback(async (params?: IFilterBudget) => {
    const { status, data } = await BudgetServices.getAll(params)
    if (status === 200) {
      setBudgets({ ...data, items: BudgetHelper.formatToDisplay(data.items) })
    }
  }, [])

  const getAllBudgetsOptions = useCallback(async (params?: IFilterBudget) => {
    let result: { label: string; value: string }[] = []

    const { status, data } = await BudgetServices.getAll(params)
    if (status === 200) {
      result = BudgetHelper.formatToOptions(data.items)
    }

    return result
  }, [])

  const getAllBudgetsOptionsDebounced = useDebouncedCallback(
    (callback: (options: any) => void, params: IFilterBudget) => {
      getAllBudgetsOptions(params).then((budgets) => {
        callback(budgets)
      })
    },
    1000
  )

  const createNewBudget = useCallback(async (formData: IStoreBudgetForm) => {
    const payload = BudgetHelper.formToPayload(formData)
    const { status, data } = await toast.promise(BudgetServices.createNew(payload), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateBudgetById = useCallback(async (id: string, formData: IUpdateBudgetForm) => {
    const payload = BudgetHelper.updateFormToPayload(formData)
    const { status, data } = await toast.promise(BudgetServices.updateById(id, payload), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const getBudgetById = useCallback(async (id: string) => {
    const { status, data } = await BudgetServices.getById(id)
    if (status === 200) {
      setBudget(data)
      return data
    }
  }, [])

  const deleteBudgetById = useCallback(async (id: string) => {
    await toast.promise(BudgetServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Orçamento excluído com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  const getPDFById = useCallback(async (id: string) => {
    const { status, data } = await BudgetServices.getPDF(id)
    if (status === 200) {
      return data
    }
  }, [])

  return {
    budget,
    budgets,
    getAllBudgetsOptions,
    getAllBudgetsOptionsDebounced,
    getAllBudgets,
    getBudgetById,
    createNewBudget,
    updateBudgetById,
    deleteBudgetById,
    getPDFById,
  }
}
