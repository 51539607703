import { IBigNumbersQuery } from 'interfaces'
import { api } from 'providers'

const getBigNumbers = (params?: IBigNumbersQuery) => api.get('/api/v1/dashboard/big-numbers', { params })

const getRevenueChart = (params?: IBigNumbersQuery) => api.get('/api/v1/dashboard/revenue-chart', { params })

export const DashboardService = {
  getBigNumbers,
  getRevenueChart,
}
