import { IBrasilApiAddress, IBrasilAPICompany } from 'interfaces'
import { BrasilApi } from 'providers'

const getAddressByZipcode = (zipcode: string) => BrasilApi.get<IBrasilApiAddress>(`/cep/v1/${zipcode}`)

const getCompanyInfoByCNPJ = (cnpj: string) => BrasilApi.get<IBrasilAPICompany>(`/cnpj/v1/${cnpj}`)

export const BrasilAPIServices = {
  getAddressByZipcode,
  getCompanyInfoByCNPJ,
}
