export enum PaymentStatusEnum {
  /**
   * @description Aguardando pagamento
   */
  PENDING = 'PENDING',
  /**
   * @description Recebida (saldo já creditado na conta)
   */
  RECEIVED = 'RECEIVED',
  /**
   * @description Pagamento confirmado (saldo ainda não creditado)
   */
  CONFIRMED = 'CONFIRMED',
  /**
   * @description VencidaREFUNDED - Estornada
   */
  OVERDUE = 'OVERDUE',
  /**
   * @description Recebida em dinheiro (não gera saldo na conta)
   */
  RECEIVED_IN_CASH = 'RECEIVED_IN_CASH',
  /**
   * @description Estorno Solicitado
   */
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  /**
   * @description Recebido chargeback
   */
  CHARGEBACK_REQUESTED = 'CHARGEBACK_REQUESTED',
  /**
   * @description Em disputa de chargeback (caso sejam apresentados documentos para contestação)
   */
  CHARGEBACK_DISPUTE = 'CHARGEBACK_DISPUTE',
  /**
   * @description Disputa vencida, aguardando repasse da adquirente
   */
  AWAITING_CHARGEBACK_REVERSAL = 'AWAITING_CHARGEBACK_REVERSAL',
  /**
   * @description Em processo de recuperação
   */
  DUNNING_REQUESTED = 'DUNNING_REQUESTED',
  /**
   * @description Recuperada
   */
  DUNNING_RECEIVED = 'DUNNING_RECEIVED',
  /**
   * @description Pagamento em análise
   */
  AWAITING_RISK_ANALYSIS = 'AWAITING_RISK_ANALYSIS',
}
