import { PaymentStatusEnum } from 'enum/payment-status.enum'
import { NumberHelper } from 'helpers'
import { IUpdateSaleInstallmentForm } from 'interfaces'
import { DateTime } from 'luxon'

const selectOptions = [
  { label: 'Pendente', value: PaymentStatusEnum.PENDING },
  { label: 'Recebida', value: PaymentStatusEnum.RECEIVED },
  { label: 'Confirmado', value: PaymentStatusEnum.CONFIRMED },
  { label: 'Vencida', value: PaymentStatusEnum.OVERDUE },
  { label: 'Recebido em dinheiro', value: PaymentStatusEnum.RECEIVED_IN_CASH },
  { label: 'Estorno solicitado', value: PaymentStatusEnum.REFUND_REQUESTED },
  { label: 'Recebido chargeback', value: PaymentStatusEnum.CHARGEBACK_REQUESTED },
  { label: 'Em disputa de chargeback', value: PaymentStatusEnum.CHARGEBACK_DISPUTE },
  { label: 'Disputa vencida', value: PaymentStatusEnum.AWAITING_CHARGEBACK_REVERSAL },
  { label: 'Em processo de recuperação', value: PaymentStatusEnum.DUNNING_REQUESTED },
  { label: 'Recuperada', value: PaymentStatusEnum.DUNNING_RECEIVED },
  { label: 'Pagamento em análise', value: PaymentStatusEnum.AWAITING_RISK_ANALYSIS },
]

const formatStatus = (status: string) => {
  switch (status) {
    case PaymentStatusEnum.PENDING:
      return 'Pendente'
    case PaymentStatusEnum.RECEIVED:
      return 'Recebida'
    case PaymentStatusEnum.CONFIRMED:
      return 'Confirmado'
    case PaymentStatusEnum.OVERDUE:
      return 'Vencida'
    case PaymentStatusEnum.RECEIVED_IN_CASH:
      return 'Recebido em dinheiro'
    case PaymentStatusEnum.REFUND_REQUESTED:
      return 'Estorno solicitado'
    case PaymentStatusEnum.CHARGEBACK_REQUESTED:
      return 'Recebido chargeback'
    case PaymentStatusEnum.CHARGEBACK_DISPUTE:
      return 'Em disputa de chargeback'
    case PaymentStatusEnum.AWAITING_CHARGEBACK_REVERSAL:
      return 'Disputa vencida'
    case PaymentStatusEnum.DUNNING_REQUESTED:
      return 'Em processo de recuperação'
    case PaymentStatusEnum.DUNNING_RECEIVED:
      return 'Recuperada'
    case PaymentStatusEnum.AWAITING_RISK_ANALYSIS:
      return 'Pagamento em análise'
    default:
      return status
  }
}

const formToUpdatePayload = (form: IUpdateSaleInstallmentForm) => ({
  status: form.status.value,
  paidAt: DateTime.fromJSDate(form.paidAt).toFormat('yyyy-MM-dd'),
  netValue: NumberHelper.formatToUSD(form.netValue),
})

export const SaleInstallmentHelper = {
  selectOptions,
  formatStatus,
  formToUpdatePayload,
}
