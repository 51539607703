export type RadioProps = {
  onChange: (value: any) => void
  id: string
  name: string
  label: string
  value: string | number
  defaultChecked?: boolean
  checked?: boolean
}

export const Radio: React.FC<RadioProps> = ({
  onChange,
  id,
  name,
  label,
  value,
  defaultChecked = false,
  checked = false,
}) => {
  return (
    <div className="flex items-center mr-4">
      <input
        onChange={onChange}
        type="radio"
        name={name}
        id={id}
        value={value}
        defaultChecked={defaultChecked}
        checked={checked}
      />
      <label htmlFor={id} className="ml-2 py-2 cursor-pointer">
        {label}
      </label>
    </div>
  )
}
