import { useController } from 'react-hook-form'
import classNames from 'classnames'

import { Radio, RadioProps } from 'components'

type RadioGroupProps = {
  label: string
  orientation?: 'horizontal' | 'vertical'
  control: any
  name: string
  items: Omit<RadioProps, 'name' | 'onChange'>[]
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ label, orientation = 'horizontal', items, name, control }) => {
  const {
    field: { value, onChange },
  } = useController({ name, control })
  return (
    <div className="block">
      <label htmlFor={name} className="input-label">
        {label}
      </label>
      <div
        className={classNames('w-full flex flex-wrap my-4', {
          'flex-row': orientation === 'horizontal',
          'flex-col': orientation === 'vertical',
        })}
      >
        {items.map((item, index) => (
          <Radio key={index} {...item} name={name} onChange={onChange} checked={value === item.value} />
        ))}
      </div>
    </div>
  )
}
