import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FaArrowDown, FaArrowUp, FaChartBar, FaClock, FaPaperclip } from 'react-icons/fa'

import { BigNumberCard, DashboardTitle, RevenueCharts, Select, Spinner } from 'components'
import { DashboardHelper, NumberHelper } from 'helpers'
import { useDashboard } from 'hooks'

export const DashboardPage = () => {
  const { bigNumbers, getBigNumbersDebounced } = useDashboard()

  const { control, watch } = useForm({ defaultValues: { period: DashboardHelper.periodOptions[0] } })

  const periodWatched = watch('period')

  useEffect(() => {
    const period = periodWatched?.value
    const options = DashboardHelper.getDateFromPeriod(period)

    getBigNumbersDebounced(options)
  }, [getBigNumbersDebounced, periodWatched])

  return (
    <div className="p-8">
      <div className="w-full flex flex-row flex-wrap justify-between items-center mb-4">
        <h1 className="mb-8 flex items-center">
          <FaChartBar color="#460edb" size="1.4rem" className="mr-2" />
          Dashboard
        </h1>
        <div className="w-full max-w-sm">
          <Select
            control={control}
            name="period"
            placeholder="Selecione"
            showLabel={false}
            options={DashboardHelper.periodOptions}
          />
        </div>
      </div>

      {bigNumbers !== undefined ? (
        <div className="w-full mt-8">
          <DashboardTitle icon={FaArrowUp} label="Receita" />
          <div className="w-full flex flex-row flex-wrap mb-4">
            <div className="flex-1 center box overflow-x-auto p-8 my-4 lg:mr-4">
              <RevenueCharts period={periodWatched?.value} />
            </div>

            <div className="w-full lg:w-64 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 my-4">
              <BigNumberCard
                Icon={FaArrowUp}
                iconColor="green"
                label="Receita"
                number={NumberHelper.formatToBRLText(bigNumbers.sales.billed.amount)}
              />
              <BigNumberCard
                Icon={FaArrowUp}
                iconColor="green"
                label="Parcelas Pagas"
                number={bigNumbers.sales.billed.quantity}
              />
              <BigNumberCard
                Icon={FaArrowDown}
                iconColor="red"
                label="Parcelas Atrasadas"
                number={NumberHelper.formatToBRLText(bigNumbers.sales.overdue.amount)}
              />
            </div>
          </div>

          <DashboardTitle icon={FaPaperclip} label="Orçamentos" />
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-4">
            <BigNumberCard
              Icon={FaArrowUp}
              iconColor="green"
              label="Faturado"
              number={NumberHelper.formatToBRLText(bigNumbers.budget.billed.amount)}
            />
            <BigNumberCard
              Icon={FaArrowUp}
              iconColor="green"
              label="Quantidade"
              number={bigNumbers.budget.billed.quantity}
            />
            <BigNumberCard
              Icon={FaClock}
              iconColor="orange"
              label="Estimados"
              number={NumberHelper.formatToBRLText(bigNumbers.budget.pending.amount)}
            />
          </div>
        </div>
      ) : (
        <Spinner label="Carregando dashboard..." />
      )}
    </div>
  )
}
