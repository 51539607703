import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FaPlus } from 'react-icons/fa'

import { Button, Breadcrumbs } from 'components'
import { useBoards } from 'hooks'
import { useSidebarContext } from 'contexts/SidebarContext'
import { IBoardSection } from 'interfaces'
import { NewBoardSectionForm, NewCardForm } from 'containers'
import { DateTime } from 'luxon-business-days'

type CardProps = {
  name: string
}

const Card: React.FC<CardProps> = ({ name }) => {
  return <div className="bg-white p-2 rounded-md mb-2 shadow-sm">{name}</div>
}

type SectionProps = {
  boardSection: IBoardSection
}

const Section: React.FC<SectionProps> = ({ boardSection }) => (
  <div className="w-full max-h-full overflow-y-auto">
    <p className="font-bold mb-4">{boardSection.name}</p>

    {boardSection.cards.map((card, index) => (
      <Card key={index} name={card.name} />
    ))}
  </div>
)

export const BoardsViewPage = () => {
  const [boardSectionIdSelected, setBoardSectionIdSelected] = useState<string | undefined>()
  const [isNewBoardSectionFormVisible, setIsNewBoardSectionFormVisible] = useState(false)

  const { id } = useParams<{ id: string }>()

  const { setIsVisible, setIsMaxWidthEnable } = useSidebarContext()
  const { boardWithCards, getAllCardsByBoardId } = useBoards()

  useEffect(() => {
    getAllCardsByBoardId(id)
  }, [getAllCardsByBoardId, id])

  useEffect(() => {
    setIsVisible(false)
    setIsMaxWidthEnable(false)
    return () => {
      setIsVisible(true)
      setIsMaxWidthEnable(true)
    }
  }, [setIsMaxWidthEnable, setIsVisible])

  return (
    <div className="w-full h-full flex flex-col">
      <div className="p-8">
        <Breadcrumbs
          links={[
            { label: 'Dashboard', href: '/dashboard' },
            { label: 'Boards', href: '/boards' },
            { label: 'Visualizar' },
          ]}
        />

        <div className="w-full flex flex-wrap items-center justify-between pt-4 pb-8">
          <h1>{boardWithCards?.name}</h1>
          <p className="mt-4 sm:mt-0">
            Encera em{' '}
            <span className="font-bold">
              {DateTime.fromISO(boardWithCards?.dueAt).setLocale('pt-BR').toFormat('DDDD')}
            </span>
          </p>
        </div>
      </div>

      <div className="w-full flex-1 overflow-x-auto flex flex-wrap sm:flex-nowrap items-start px-8">
        {boardWithCards?.boardSections?.map((boardSection, boardSectionIndex) => (
          <div key={boardSectionIndex} className="board-section sm:mr-2 bg-gray-50 p-4 mb-4 rounded-md shadow-sm">
            <Section boardSection={boardSection} />

            {boardSectionIdSelected === boardSection.id ? (
              <NewCardForm
                onSave={() => getAllCardsByBoardId(id)}
                onClose={() => setBoardSectionIdSelected(undefined)}
                boardSectionId={boardSection.id}
              />
            ) : (
              <Button
                type="button"
                className="button--small button--light button--full mt-4"
                onClick={() => setBoardSectionIdSelected(boardSection.id)}
              >
                <FaPlus color="#666" className="mr-2" />
                Novo card
              </Button>
            )}
          </div>
        ))}

        <div className="board-section sm:mr-2 mb-4">
          {isNewBoardSectionFormVisible ? (
            <NewBoardSectionForm
              boardId={id}
              onSave={() => {
                setIsNewBoardSectionFormVisible(false)
                getAllCardsByBoardId(id)
              }}
              onClose={() => setIsNewBoardSectionFormVisible(false)}
            />
          ) : (
            <Button
              type="button"
              className="button--light button--small button--full"
              onClick={() => setIsNewBoardSectionFormVisible(true)}
            >
              <FaPlus color="#666" className="mr-2" />
              Nova seção
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
