import { useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { FaExternalLinkAlt, FaPrint } from 'react-icons/fa'

import { Breadcrumbs, Spinner, Table, Tabs } from 'components'
import { useSales } from 'hooks'
import { SaleInstallmentsTab } from 'pages'
import { ISaleContract } from 'interfaces'
import { NumberHelper } from 'helpers'

export const SalesEditPage = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { saleWithInstallments, getInstallmentsById } = useSales()

  const handleGetInstallmentById = useCallback(async () => {
    await getInstallmentsById(id)
  }, [getInstallmentsById, id])

  useEffect(() => {
    handleGetInstallmentById()
  }, [handleGetInstallmentById])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Vendas', href: '/vendas' }, { label: 'Alterar' }]}
      />

      <div className="box p-8 w-full">
        <div className="mb-4 flex flex-row flex-wrap items-start justify-between">
          <div className="w-full max-w-xl">
            <p className="font-bold text-gray-700">
              {saleWithInstallments?.budget.customer?.fantasyName || saleWithInstallments?.budget.customer.contactName}
            </p>
            <h1 className="mb-2">{saleWithInstallments?.budget.title}</h1>
            <p className="font-bold text-gray-700 text-lg mb-4">
              {NumberHelper.formatToBRLText(saleWithInstallments?.value)}
            </p>
          </div>
          <button
            className="button button--small button--light"
            onClick={() => history.push(`/orcamentos/${saleWithInstallments?.budget.id}/pdf`)}
          >
            Orçamento
            <FaExternalLinkAlt color="gray" className="ml-2" />
          </button>
        </div>

        {saleWithInstallments ? (
          <Tabs
            header={['Parcelas', 'Contratos']}
            selectedIndex={0}
            items={[
              {
                Component: (
                  <SaleInstallmentsTab
                    salePaymentMethods={saleWithInstallments?.salePaymentMethods || []}
                    onInstallmentUpdate={handleGetInstallmentById}
                  />
                ),
              },
              {
                Component: (
                  <div className="py-4">
                    <Table
                      data={saleWithInstallments?.saleContracts || []}
                      headers={[{ key: 'name', label: 'Contrato' }]}
                      renderItem={(saleContract: ISaleContract) => ({
                        ...saleContract,
                        name: saleContract.contract.name,
                      })}
                      actions={[
                        {
                          label: <FaPrint color="gray" />,
                          onPress: (saleContract: ISaleContract) => {
                            const win = window.open(
                              `/vendas/${saleWithInstallments.id}/contrato/${saleContract.contract.id}`,
                              '_blank'
                            )
                            if (win) win.focus()
                          },
                        },
                      ]}
                    />
                  </div>
                ),
              },
            ]}
          />
        ) : (
          <Spinner label="Carregando venda..." />
        )}
      </div>
    </div>
  )
}
