import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateCustomerSchema = yup
  .object()
  .shape({
    personType: yup.string().nullable().required('Informe se é Pessoa Jurídica ou Física'),
    fantasyName: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o nome fantasia'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    socialName: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha a razão social'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    cnpj: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CNPJ'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    contactName: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha seu nome completo'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    cpf: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CPF'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    zipcode: yup.string().nullable(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
    neighborhood: yup.string().nullable(),
    addressName: yup.string().nullable(),
    addressNumber: yup.string().nullable(),
    email: yup.string().email('Informe um e-mail válido').required('Informe o e-mail'),
    phone: yup.string().nullable(),
    cellphone: yup.string().nullable(),
    website: yup.string().nullable(),
    notificationEnabled: yup.boolean().nullable(),
  })
  .required()
export const CreateCustomerResolver = yupResolver(CreateCustomerSchema)
