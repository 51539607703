import { AxiosResponse } from 'axios'

import { api } from 'providers'
import {
  IForgotPassowrdPayload,
  IForgotPassowrdResponse,
  ISignInPayload,
  ISignInResponse,
  ISignUpPayload,
  ISignUpResponse,
} from 'interfaces'

const signIn = (email: string, password: string) =>
  api.post<ISignInPayload, AxiosResponse<ISignInResponse>>('/api/auth/sign-in', { email, password })

const signUp = (user: ISignUpPayload) =>
  api.post<ISignUpPayload, AxiosResponse<ISignUpResponse>>('/api/auth/sign-up', user)

const forgotPassword = (email: string) =>
  api.post<IForgotPassowrdPayload, AxiosResponse<IForgotPassowrdResponse>>('/api/auth/forgot-password', { email })

export const AuthServices = {
  signIn,
  signUp,
  forgotPassword,
}
