import { NumberHelper } from 'helpers'
import { IService, IStoreService } from 'interfaces'

const formatToDisplay = (services: IService[]) =>
  services.map((service) => ({
    ...service,
    value: NumberHelper.formatToBRLText(service.value),
  }))

const payloadToForm = (service: IService) => ({
  ...service,
  value: NumberHelper.formatToBRL(service.value),
})

const formToStorePayload = (service: IStoreService) => ({
  ...service,
  value: NumberHelper.formatToUSD(service.value),
})

const formatToOptions = (services: IService[]) =>
  services.map((service, index) => ({
    label: service.name,
    value: service.id,
  }))

export const ServiceHelper = {
  formatToDisplay,
  payloadToForm,
  formToStorePayload,
  formatToOptions,
}
