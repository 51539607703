import { AxiosResponse } from 'axios'

import { IContract, IFilterContract, IStoreContract, IUpdateContract } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterContract) => api.get('/api/v1/contracts', { params })

const getById = (id: string) => api.get<IContract>(`/api/v1/contracts/${id}`)

const createNew = (contract: IStoreContract) =>
  api.post<IStoreContract, AxiosResponse<IContract>>('/api/v1/contracts', contract)

const updateById = (id: string, service: IUpdateContract) =>
  api.put<IUpdateContract, AxiosResponse<IContract>>(`/api/v1/contracts/${id}`, service)

const deleteById = (id: string) => api.delete(`/api/v1/contracts/${id}`)

export const ContractsServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
}
