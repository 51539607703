import { Fragment } from 'react'
import { Link } from 'react-router-dom'

type BreadcrumbsProps = {
  links: { label: string; href?: string }[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ links }) => {
  return (
    <div className="flex mb-6">
      {links.map((link, index) => {
        if (link.href) {
          return (
            <Fragment key={index}>
              <Link className="p-0" to={link.href}>
                {link.label}
              </Link>
              <span className="text-gray-400 block px-2">/</span>
            </Fragment>
          )
        }

        return (
          <p key={index} className="block text-gray-500">
            {link.label}
          </p>
        )
      })}
    </div>
  )
}
