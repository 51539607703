import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { BoardsServices } from 'services'
import { IBoard, IFilterBoard, IPagination, IStoreBoardForm, IStoreCard } from 'interfaces'
import { BoardHelper } from 'helpers'

export const useBoards = () => {
  const [boards, setBoards] = useState<IPagination<Omit<IBoard, 'boardSections'>>>({
    currentPage: 1,
    totalPages: 0,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })
  const [boardWithCards, setBoardWithCards] = useState<IBoard>()

  const getAllBoards = useCallback(async (params?: IFilterBoard) => {
    const { status, data } = await BoardsServices.getAll(params)
    if (status === 200) {
      setBoards({ ...data, items: BoardHelper.formatToDisplay(data.items) })
    }
  }, [])

  const createNewBoard = useCallback(async (board: IStoreBoardForm) => {
    const payload = BoardHelper.formToStorePayload(board)
    const { status, data } = await toast.promise(BoardsServices.createNew(payload), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Novo board cadastrado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const deleteBoardById = useCallback(async (id: string) => {
    await toast.promise(BoardsServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Board excluído com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  const getAllCardsByBoardId = useCallback(async (id: string) => {
    const { status, data } = await BoardsServices.getAllCardsByBoardId(id)
    if (status === 200) {
      setBoardWithCards(data)
    }
  }, [])

  const createNewCard = useCallback(async (boardSectionId: string, data: IStoreCard) => {
    await toast.promise(BoardsServices.createNewCard(boardSectionId, data), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Novo card cadastrado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })
  }, [])

  const createNewBoardSection = useCallback(async (boardId: string, data: IStoreCard) => {
    await toast.promise(BoardsServices.createNewBoardSection(boardId, data), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Nova seção cadastrada com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })
  }, [])

  return {
    boards,
    boardWithCards,
    getAllBoards,
    createNewBoard,
    deleteBoardById,
    getAllCardsByBoardId,
    createNewCard,
    createNewBoardSection,
  }
}
