import { AxiosResponse } from 'axios'

import { IBoard, IStoreBoard, IFilterBoard, IPagination, IStoreCard, ICard, IBoardSection } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterBoard) => api.get<IPagination<IBoard>>('/api/v1/boards', { params })

const getAllCardsByBoardId = (id: string) => api.get<IBoard>(`/api/v1/boards/${id}/cards`)

const createNew = (board: IStoreBoard) => api.post<IStoreBoard, AxiosResponse<IBoard>>('/api/v1/boards', board)

const deleteById = (id: string) => api.delete(`/api/v1/boards/${id}`)

const createNewCard = (boardSectionId: string, data: IStoreCard) =>
  api.post<IStoreCard, AxiosResponse<ICard>>(`/api/v1/boards/board-sections/${boardSectionId}/cards`, data)

const createNewBoardSection = (boardId: string, data: IStoreCard) =>
  api.post<IStoreCard, AxiosResponse<IBoardSection>>(`/api/v1/boards/${boardId}/board-sections`, data)

export const BoardsServices = {
  getAll,
  createNew,
  deleteById,
  getAllCardsByBoardId,
  createNewCard,
  createNewBoardSection,
}
