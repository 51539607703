import { AxiosResponse } from 'axios'

import { IFilterService, IPagination, IService, IStoreService, IUpdateService } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterService) => api.get<IPagination<IService>>('/api/v1/services', { params })

const getById = (id: string) => api.get<IService>(`/api/v1/services/${id}`)

const createNew = (service: IStoreService) =>
  api.post<IStoreService, AxiosResponse<IService>>('/api/v1/services', service)

const updateById = (id: string, service: IUpdateService) =>
  api.put<IUpdateService, AxiosResponse<IService>>(`/api/v1/services/${id}`, service)

const deleteById = (id: string) => api.delete(`/api/v1/services/${id}`)

export const ServicesServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
}
