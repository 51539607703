import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateServicesSchema = yup
  .object({
    name: yup.string().required('Informe um nome'),
    description: yup.string().nullable(),
    content: yup.string().nullable(),
    value: yup.string().nullable(),
    hours: yup.number().min(1, 'O mínimo é 1 hora').nullable(),
  })
  .required()
export const CreateServicesResolver = yupResolver(CreateServicesSchema)
