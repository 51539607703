import { Route } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { Sidebar, Nav } from 'components'
import classNames from 'classnames'
import { useSidebarContext } from 'contexts/SidebarContext'
import { useEffect } from 'react'

export const AdminLayoutRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  )
}

export const AdminLayout: React.FC = ({ children }) => {
  const { isVisible, setIsVisible, isMaxWidthEnable } = useSidebarContext()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  useEffect(() => {
    setIsVisible(!isTabletOrMobile)
  }, [isTabletOrMobile, setIsVisible])

  return (
    <>
      <Nav />
      <div className="w-full min-h-screen flex items-stretch nav-layout-content relative">
        <Sidebar isVisible={isVisible} />
        <div
          className={classNames('flex-1 max-w-full bg-gray-100 transition-all ease-in-out duration-300', {
            'ml-96': isVisible,
          })}
        >
          <div className={classNames('m-auto w-full h-full overflow-x-auto', { 'max-w-6xl': isMaxWidthEnable })}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
