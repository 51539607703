import { AxiosResponse } from 'axios'

import { IBrasilAPICompany, ICompanies, IFilterCompanies, IStoreCompanies, IUpdateCompanies } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterCompanies) => api.get('/api/v1/companies', { params })

const getById = (id: string) => api.get<ICompanies>(`/api/v1/companies/${id}`)

const createNew = (company: IStoreCompanies) =>
  api.post<IStoreCompanies, AxiosResponse<IBrasilAPICompany>>('/api/v1/companies', company)

const updateById = (id: string, company: IUpdateCompanies) =>
  api.put<IUpdateCompanies, AxiosResponse<IBrasilAPICompany>>(`/api/v1/companies/${id}`, company)

const deleteById = (id: string) => api.delete(`/api/v1/companies/${id}`)

const uploadLogo = (id: string, file: any) => {
  const formData = new FormData()
  formData.append('upload', file)

  return api.post(`/api/v1/companies/${id}/logo`, formData)
}

export const CompaniesServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
  uploadLogo,
}
