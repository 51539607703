import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { Breadcrumbs, Button, Input, TextArea } from 'components'
import { useServices } from 'hooks'
import { CreateServicesResolver } from 'validations'

export const ServicesAddPage = () => {
  const history = useHistory()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ resolver: CreateServicesResolver })
  const { createNewService } = useServices()

  const onSubmit = async (value: any) => {
    const response = await createNewService(value)
    if (response) {
      history.push('/servicos')
    }
  }

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Serviços', href: '/servicos' },
          { label: 'Adicionar' },
        ]}
      />

      <div className="box p-8 w-full">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="mb-8">Adicionar Serviço</h1>

          <div className="sm:grid gap-2 grid-cols-3">
            <Input control={control} name="name" label="Nome" />
            <Input control={control} name="value" label="Valor" currency />
            <Input control={control} name="hours" label="Horas" type="number" />
          </div>

          <TextArea
            control={control}
            name="content"
            label="Conteúdo"
            placeholder="Descreva com mais detalhes o que consiste esse serviço, quais são as etapas, qual é o entregável para o cliente."
          />

          <div className="w-full flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
