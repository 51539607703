import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaHammer } from 'react-icons/fa'

import { useServices } from 'hooks'
import { Breadcrumbs, Pagination, SearchBar, Table } from 'components'

export const ServicesPage = () => {
  const [params, setParams] = useState<{ [key: string]: any }>()

  const history = useHistory()
  const { services, getAllServices, deleteServiceById } = useServices()

  const handleDeleteItem = (id: string) => {
    deleteServiceById(id).then(() => getAllServices())
  }

  useEffect(() => {
    getAllServices(params)
  }, [getAllServices, params])

  return (
    <div className="p-8">
      <Breadcrumbs links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Serviços' }]} />

      <div className="box p-8 w-full">
        <h1 className="mb-8 flex items-center">
          <FaHammer color="#460edb" size="1.4rem" className="mr-2" />
          Serviços
        </h1>

        <div className="w-full flex flex-row flex-wrap items-start justify-between mb-4">
          <div className="flex-1 max-w-lg">
            <SearchBar onSubmit={({ search }) => setParams({ search })} />
          </div>
          <Link className="button button--primary" to="/servicos/adicionar">
            Adicionar
          </Link>
        </div>

        <Table
          headers={[
            { key: 'name', label: 'Nome' },
            { key: 'hours', label: 'Horas', width: 100 },
            { key: 'value', label: 'Valor', width: 150 },
          ]}
          actions={[{ label: 'Excluir', onPress: (item) => handleDeleteItem(item.id) }]}
          onClick={(item) => history.push(`/servicos/alterar/${item.id}`)}
          data={services.items}
        />

        <Pagination
          currentPage={services.currentPage}
          totalPages={services.totalPages}
          onClick={(currentPage: number) => setParams((prev) => ({ ...prev, currentPage }))}
        />
      </div>
    </div>
  )
}
