import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import { CustomersServices } from 'services'
import { ICustomer, IFilterCustomer, IPagination, IStoreCustomer } from 'interfaces'
import { CustomerHelper } from 'helpers'

export const useCustomers = () => {
  const [customers, setCustomers] = useState<IPagination<ICustomer>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllCustomers = useCallback(async (params?: IFilterCustomer) => {
    const { status, data } = await CustomersServices.getAll(params)

    if (status === 200) {
      setCustomers(data)
    }
  }, [])

  const getAllCustomersOptions = useCallback(async (params?: IFilterCustomer) => {
    let result: { label: string; value: string }[] = []

    const { status, data } = await CustomersServices.getAll(params)
    if (status === 200) {
      result = CustomerHelper.formatToOptions(data.items)
    }

    return result
  }, [])

  const getAllCustomersOptionsDebounced = useDebouncedCallback(
    (callback: (options: any) => void, params?: IFilterCustomer) => {
      getAllCustomersOptions(params).then((customers) => callback(customers))
    },
    1000
  )

  const getCustomerById = useCallback(async (id: string) => {
    const { status, data } = await CustomersServices.getById(id)

    if (status === 200) {
      return data
    }
  }, [])

  const createNewCustomer = useCallback(async (customer: IStoreCustomer) => {
    const { status, data } = await toast.promise(CustomersServices.createNew(customer), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateCustomerById = useCallback(async (id: string, customer: IStoreCustomer) => {
    const { status, data } = await toast.promise(CustomersServices.updateById(id, customer), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const deleteCustomerById = useCallback(async (id: string) => {
    await toast.promise(CustomersServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Empresa excluída com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  return {
    customers,
    getAllCustomers,
    getAllCustomersOptions,
    getAllCustomersOptionsDebounced,
    getCustomerById,
    createNewCustomer,
    updateCustomerById,
    deleteCustomerById,
  }
}
