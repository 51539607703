import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { ContractsServices } from 'services'
import { IContract, IFilterContract, IPagination, IStoreContract, IUpdateContract } from 'interfaces'

export const useContracts = () => {
  const [contracts, setContracts] = useState<IPagination<IContract>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllContracts = useCallback(async (params?: IFilterContract) => {
    const { status, data } = await ContractsServices.getAll(params)

    if (status === 200) {
      setContracts(data)
    }
  }, [])

  const getContractById = useCallback(async (id: string) => {
    const { status, data } = await ContractsServices.getById(id)

    if (status === 200) {
      return data
    }
  }, [])

  const createNewContract = useCallback(async (contract: IStoreContract) => {
    const { status, data } = await toast.promise(ContractsServices.createNew(contract), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateContractById = useCallback(async (id: string, contract: IUpdateContract) => {
    const { status, data } = await toast.promise(ContractsServices.updateById(id, contract), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const deleteContractById = useCallback(async (id: string) => {
    await toast.promise(ContractsServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Cadastro excluído com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  return {
    contracts,
    getAllContracts,
    getContractById,
    createNewContract,
    updateContractById,
    deleteContractById,
  }
}
