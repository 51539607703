import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'

import { Breadcrumbs, Button, Editor, EditorVariables, Input } from 'components'
import { useContracts } from 'hooks'
import { CreateContractsResolver } from 'validations'

export const ContractsEditPage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm({ resolver: CreateContractsResolver })
  const { updateContractById, getContractById } = useContracts()

  const onSubmit = async (value: any) => {
    const response = await updateContractById(id, value)
    if (response) {
      history.push('/contratos')
    }
  }

  useEffect(() => {
    getContractById(id).then((response) => {
      if (response) {
        setValue('name', response.name)
        setValue('content', response.content)
      }
    })
  }, [getContractById, id, setValue])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Contratos', href: '/contratos' },
          { label: 'Alterar' },
        ]}
      />

      <div className="box p-8 w-full">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8 flex flex-row items-center justify-between">
            <h1 className="flex-1">Alterar Contrato</h1>
            <div className="flex flex-row pt-4 justify-end">
              <Button className="button--primary" type="submit" isLoading={isSubmitting}>
                Salvar
              </Button>
            </div>
          </div>

          <Input control={control} name="name" placeholder="Nome" />
          <Editor control={control} name="content" />

          <div className="w-full my-4">
            <EditorVariables />
          </div>

          <div className="flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
