import { ImSpinner8 } from 'react-icons/im'

type SpinnerProps = {
  label?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ label }) => (
  <div className="w-full center">
    <ImSpinner8 className="animate-spin" />
    {label && <p className="ml-2">{label}</p>}
  </div>
)
