import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Input } from 'components'
import { SigUpUserResolver } from 'validations'
import { useAuth } from 'hooks'
import { ISignUpResponse } from 'interfaces'

type SignUpUserProps = {
  onSuccess: (data: ISignUpResponse) => void
}

export const SignUpUser: React.FC<SignUpUserProps> = ({ onSuccess }) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ resolver: SigUpUserResolver })

  const { signUp } = useAuth()

  const onSubmit = useCallback(
    async (data) => {
      const signUpResponse = await signUp(data)
      if (signUpResponse) {
        onSuccess(signUpResponse)
      }
    },
    [onSuccess, signUp]
  )

  return (
    <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="sm:grid gap-2 grid-cols-2">
        <Input control={control} name="firstName" label="Nome" />

        <Input control={control} name="lastName" label="Sobrenome" />
      </div>

      <Input control={control} name="email" label="E-mail" />

      <div className="sm:grid gap-2 grid-cols-2">
        <Input type="password" control={control} name="password" label="Senha" />

        <Input type="password" control={control} name="confirmPassword" label="Confirme sua senha" />
      </div>

      <div className="w-full flex flex-row items-center justify-end py-4">
        <Button className="button--primary" type="submit" isLoading={isSubmitting}>
          Próximo
        </Button>
      </div>
    </form>
  )
}
