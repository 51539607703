import { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { DashboardService } from 'services'
import { IBigNumbersQuery, IDashboardBigNumbers, IDashboardRevenueChart } from 'interfaces'

export const useDashboard = () => {
  const [bigNumbers, setBigNumbers] = useState<IDashboardBigNumbers>()
  const [revenueChart, setRevenueChart] = useState<IDashboardRevenueChart>()

  const getBigNumbers = useCallback(async (options?: IBigNumbersQuery) => {
    const { status, data } = await DashboardService.getBigNumbers(options)
    if (status === 200) {
      setBigNumbers(data)
    }
  }, [])

  const getBigNumbersDebounced = useDebouncedCallback(getBigNumbers, 1000)

  const getRevenueChart = useCallback(async (options?: IBigNumbersQuery) => {
    const { status, data } = await DashboardService.getRevenueChart(options)
    if (status === 200) {
      setRevenueChart(data)
    }
  }, [])

  const getRevenueChartDebounced = useDebouncedCallback(getRevenueChart, 1000)

  return {
    bigNumbers,
    revenueChart,
    getBigNumbers,
    getBigNumbersDebounced,
    getRevenueChart,
    getRevenueChartDebounced,
  }
}
