import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FaBuilding } from 'react-icons/fa'

import { Breadcrumbs, Table } from 'components'
import { useCompanies } from 'hooks'
import { ICompanies } from 'interfaces'

export const CompaniesPage = () => {
  const history = useHistory()
  const { companies, getAllCompanies } = useCompanies()

  useEffect(() => {
    getAllCompanies()
  }, [getAllCompanies])

  return (
    <div className="p-8">
      <Breadcrumbs links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Empresas' }]} />

      <div className="box p-8 w-full">
        <h1 className="mb-8 flex items-center">
          <FaBuilding color="#460edb" size="1.4rem" className="mr-2" />
          Empresas
        </h1>

        <Table
          headers={[
            { key: 'fantasyName', label: 'Nome' },
            { key: 'personType', label: 'Tipo' },
            { key: 'cnpj', label: 'CNPJ/CPF', width: 200 },
            { key: 'cellphone', label: 'Celular', width: 200 },
          ]}
          renderItem={(item: ICompanies) => ({
            ...item,
            fantasyName: item.fantasyName || item.name,
            cnpj: item.cnpj || item.cpf,
          })}
          onClick={(item) => history.push(`/empresas/alterar/${item.id}`)}
          data={companies.items}
        />
      </div>
    </div>
  )
}
