import { useForm } from 'react-hook-form'

import { Input } from '..'

type SearchBarProps = {
  onSubmit: (data: { search: string }) => void
}

export const SearchBar: React.FC<SearchBarProps> = ({ onSubmit }) => {
  const { control, handleSubmit } = useForm()

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <Input control={control} name="search" placeholder="Digite e aperte enter" showLabel={false} />
    </form>
  )
}
