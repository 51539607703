const styles = {
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '0.75rem',
    borderColor: 'rgba(229, 231, 235, var(--tw-border-opacity))',
    backgroundColor: state.isSelected ? 'var(--primary)' : 'transparent',
    color: state.isSelected ? '#fff' : '#000',
    ':hover': {
      backgroundColor: state.isSelected ? 'var(--primary)' : 'rgba(245, 243, 255, 1)',
      color: state.isSelected ? '#fff' : '#000',
    },
    ':active': {
      backgroundColor: 'var(--primary)',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    padding: '0.25rem',
    borderColor: 'rgba(229, 231, 235, var(--tw-border-opacity))',
    boxShadow: state.isFocused ? '0 0 0 1px rgba(245, 243, 255, 1)' : 'none',
    outline: state.isFocused ? '4px solid rgba(245, 243, 255, 1)' : '0px',
    transition: 'none',
    ':hover': {
      borderColor: 'rgba(229, 231, 235, var(--tw-border-opacity))',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '2px',
  }),
}

export const SelectHelper = {
  styles,
}
