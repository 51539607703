import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaCheck, FaPaperclip, FaTimes } from 'react-icons/fa'
import { useForm } from 'react-hook-form'

import { useBudgets, useCustomers } from 'hooks'
import { Breadcrumbs, Pagination, SearchBar, Select, Table } from 'components'
import { IBudget } from 'interfaces'
import { CustomerHelper } from 'helpers'

export const BudgetsPage = () => {
  const [params, setParams] = useState<{ [key: string]: any }>()

  const history = useHistory()
  const { control } = useForm()
  const { budgets, getAllBudgets, deleteBudgetById } = useBudgets()
  const { customers, getAllCustomersOptionsDebounced } = useCustomers()

  const customersOption = useMemo(() => CustomerHelper.formatToOptions(customers.items), [customers?.items])

  const handleDeleteItem = (id: string) => {
    deleteBudgetById(id).then(() => getAllBudgets())
  }

  useEffect(() => {
    getAllBudgets(params)
  }, [getAllBudgets, params])

  return (
    <div className="p-8">
      <Breadcrumbs links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Orçamentos' }]} />

      <div className="box p-8 w-full">
        <h1 className="mb-8 flex items-center">
          <FaPaperclip color="#460edb" size="1.4rem" className="mr-2" />
          Orçamentos
        </h1>

        <div className="w-full flex flex-row flex-wrap items-start justify-between content-around mb-4">
          <div className="w-full lg:w-auto lg:flex-1 grid grid-cols-1 lg:grid-cols-2 mb-4 gap-0 lg:gap-2 lg:mb-0">
            <SearchBar onSubmit={({ search }) => setParams((prev) => ({ ...prev, search }))} />
            <div className="flex-1 lg:mb-0">
              <Select
                async
                defaultOptions={customersOption}
                loadOptions={(search: string, callback) => {
                  getAllCustomersOptionsDebounced(callback, { search })
                }}
                control={control}
                onChange={({ value }) => setParams((prev) => ({ ...prev, customerId: value }))}
                name="customerId"
                placeholder="Cliente"
                showLabel={false}
              />
            </div>
          </div>
          <Link className="button button--primary lg:ml-4" to="/orcamentos/adicionar">
            Adicionar
          </Link>
        </div>

        <Table
          headers={[
            { key: 'title', label: 'Título' },
            { key: 'fantasyName', label: 'Cliente' },
            { key: 'value', label: 'Valor', width: 100 },
            { key: 'billed', label: 'Faturado', width: 100 },
          ]}
          renderItem={(item: IBudget) => ({
            ...item,
            fantasyName: item.customer?.fantasyName || item.customer?.contactName || '',
            billed: item.billed ? <FaCheck color="green" /> : <FaTimes color="red" />,
          })}
          actions={[
            {
              label: 'PDF',
              onPress: (item: IBudget) => history.push(`/orcamentos/${item.id}/pdf`),
            },
            { label: 'Excluir', onPress: (item) => handleDeleteItem(item.id) },
          ]}
          onClick={(item: IBudget) => (item.billed ? null : history.push(`/orcamentos/alterar/${item.id}`))}
          data={budgets.items}
        />

        <Pagination
          currentPage={budgets.currentPage}
          totalPages={budgets.totalPages}
          onClick={(currentPage: number) => setParams((prev) => ({ ...prev, currentPage }))}
        />
      </div>
    </div>
  )
}
