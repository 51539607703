import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { Breadcrumbs, Button, Editor, EditorVariables, Input } from 'components'
import { useContracts } from 'hooks'
import { CreateContractsResolver } from 'validations'

export const ContractsAddPage = () => {
  const history = useHistory()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: CreateContractsResolver,
    defaultValues: {
      name: undefined,
      content: `
<h3><b>CONTRATO</b></h3>
<br>
<h3><b>DAS PARTES</b></h3>
<br>
<b>CONTRATADO</b>: Empresa $CONTRATADO_RAZAO_SOCIAL, inscrita no CNPJ número $CONTRATADO_CNPJ, localizada na $CONTRATADO_ENDERECO $CONTRATADO_NUMERO, bairro $CONTRATADO_BAIRRO na cidade $CONTRATADO_CIDADE – $CONTRATADO_UF, CEP $CONTRATADO_CEP.
<br><br>
<b>CONTRATANTE</b>: Empresa $CONTRATANTE_RAZAO_SOCIAL, inscrita no CNPJ número $CONTRATANTE_CNPJ, localizada na $CONTRATANTE_ENDERECO, $CONTRATANTE_NUMERO - $CONTRATANTE_BAIRRO na cidade $CONTRATANTE_CIDADE - $CONTRATANTE_UF, CEP $CONTRATANTE_CEP.
<br><br>`,
    },
  })
  const { createNewContract } = useContracts()

  const onSubmit = async (value: any) => {
    const response = await createNewContract(value)
    if (response) {
      history.push('/contratos')
    }
  }

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Contratos', href: '/contratos' },
          { label: 'Adicionar' },
        ]}
      />

      <div className="box p-8 w-full">
        <h1 className="mb-8">Adicionar Contrato</h1>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Input control={control} name="name" placeholder="Nome" />
          <Editor control={control} name="content" />

          <div className="w-full my-4">
            <EditorVariables />
          </div>

          <div className="flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Cadastrar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
