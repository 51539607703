import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { AuthServices } from 'services'
import { ISignUpPayload } from 'interfaces'
import { AuthHelper } from 'helpers'

export const useAuth = () => {
  const signIn = useCallback(async (email: string, password: string) => {
    let result

    const { status, data } = await toast.promise(AuthServices.signIn(email, password), {
      pending: 'Conectando, por favor, aguarde...',
      success: 'Conectado com sucesso!',
      error: 'Houve algum erro inesperado ao conectar!',
    })

    if (status === 200) {
      result = data
    }

    return result
  }, [])

  const signUp = useCallback(async (user: ISignUpPayload) => {
    let result

    const { status, data } = await toast.promise(AuthServices.signUp(user), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve algum erro inesperado ao realizar o seu cadastro!',
    })

    if (status === 201) {
      const signInResponse = await AuthServices.signIn(user.email, user.password)
      if (signInResponse.status === 200 && signInResponse.data?.token) {
        AuthHelper.setBearerToken(signInResponse.data.token)
      }

      result = data
    }

    return result
  }, [])

  const forgotPassword = useCallback(async (email: string) => {
    const { status, data } = await AuthServices.forgotPassword(email)
    if (status === 200) {
      toast.success(data.message)
    }
  }, [])

  return {
    signIn,
    signUp,
    forgotPassword,
  }
}
