import { Accordion } from 'components'

export const EditorVariables = () => {
  return (
    <Accordion
      items={[
        {
          label: 'Variáveis',
          Component: (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th className="p-2">Variável</th>
                    <th className="p-2">Descrição do conteúdo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$CONTRATADO_RAZAO_SOCIAL</td>
                    <td>A razão social da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_CNPJ</td>
                    <td>O CNPJ da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_ENDERECO</td>
                    <td>O endereço da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_NUMERO</td>
                    <td>O número do seu endereço</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_BAIRRO</td>
                    <td>O bairro do endereço da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_CIDADE</td>
                    <td>A cidade do endereço da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_UF</td>
                    <td>O estado do endereço da sua empresa</td>
                  </tr>
                  <tr>
                    <td>$CONTRATADO_CEP</td>
                    <td>O CEP do endereço da sua empresa</td>
                  </tr>

                  <tr>
                    <td>$CONTRATANTE_RAZAO_SOCIAL</td>
                    <td>A razão social do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_CNPJ</td>
                    <td>O CNPJ do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_ENDERECO_NOME</td>
                    <td>O endereço do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_ENDERECO_NUMERO</td>
                    <td>O número do seu endereço</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_BAIRRO</td>
                    <td>O bairro do endereço do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_CIDADE</td>
                    <td>A cidade do endereço do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_UF</td>
                    <td>O estado do endereço do cliente</td>
                  </tr>
                  <tr>
                    <td>$CONTRATANTE_CEP</td>
                    <td>O CEP do endereço do cliente</td>
                  </tr>

                  <tr>
                    <td>$VALOR_REAIS</td>
                    <td>O valor do contrato em reais, ex.: R$ 900</td>
                  </tr>
                  <tr>
                    <td>$VALOR_EXTENSO</td>
                    <td>O valor do contrato em reais por extenso, ex.: novecentos reais</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        },
      ]}
    />
  )
}
