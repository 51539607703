import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { DateTime } from 'luxon'

import { useDashboard } from 'hooks'
import { DashboardHelper } from 'helpers'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend)

type RevenueChartsProps = {
  period: string
}

export const RevenueCharts: React.FC<RevenueChartsProps> = ({ period }) => {
  const { revenueChart, getRevenueChartDebounced } = useDashboard()

  const [target, setTarget] = useState<string[]>([])
  const [labels, setLabels] = useState<string[]>([])

  const getLabels = useCallback((period: string) => {
    let lastDays = 30
    if (period === 'last_14') lastDays = 14
    if (period === 'last_7') lastDays = 7

    const target = DashboardHelper.getLastDays(lastDays)
    const labels = DashboardHelper.getLastDays(lastDays).map((day) =>
      DateTime.fromFormat(day, 'yyyy-MM-dd').toFormat('dd/MM')
    )

    setTarget(target)
    setLabels(labels)
  }, [])

  useEffect(() => {
    getLabels(period)
  }, [getLabels, period])

  useEffect(() => {
    const options = DashboardHelper.getDateFromPeriod(period)

    getRevenueChartDebounced(options)
  }, [getRevenueChartDebounced, period])

  const renderChart = useMemo(() => {
    const revenueData = target.map((n) => revenueChart?.revenues.find((r) => r.date === n)?.amount || 0)

    const data = {
      labels,
      datasets: [
        {
          label: 'Receita R$',
          data: revenueData,
          backgroundColor: 'rgb(99, 255, 146)',
        },
      ],
    }

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: false,
        },
      },
    }

    return <Bar width={600} height={400} options={options} data={data} />
  }, [labels, revenueChart?.revenues, target])

  return renderChart
}
