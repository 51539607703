import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { SigInResolver } from 'validations'
import { Button, Input } from 'components'
import { useAuth } from 'hooks'
import { useAuthContext } from 'contexts/AuthContext'

export const SignInPage = () => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ resolver: SigInResolver })
  const { signIn } = useAuth()
  const { setUser } = useAuthContext()
  const history = useHistory()

  const onSubmit = useCallback(
    async (data) => {
      const { email, password } = data
      const response = await signIn(email, password)
      if (response) {
        const { token, user } = response
        setUser({ token, user })
        history.push('/dashboard')
      }
    },
    [history, setUser, signIn]
  )

  return (
    <div className="w-full flex items-stretch flex-wrap min-h-screen">
      <div className="w-full lg:flex-1 p-8 center">
        <form className="w-full max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-center pt-2 pb-4">Acesse sua conta</h1>

          <Input control={control} name="email" placeholder="E-mail" />
          <Input type="password" control={control} name="password" placeholder="Senha" />

          <div className="w-full center flex-col pt-4">
            <Button type="submit" className="button--primary" isLoading={isSubmitting}>
              Conectar
            </Button>

            <div className="mt-4">
              <Link to="/recuperar-senha" className="link">
                Esqueceu sua senha?
              </Link>
            </div>
          </div>
        </form>
      </div>

      <div className="w-full lg:w-1/3 p-8 center flex-col bg-primary text-white text-center">
        <div className="mb-6">
          <h1 className="text-white">Ainda não tem uma conta?</h1>
          <p className="text-lg">Crie sua conta gratuita agora mesmo</p>
        </div>

        <Link to="/cadastrar" className="button button--light">
          <span className="text-white">Crie sua conta</span>
        </Link>
      </div>
    </div>
  )
}
