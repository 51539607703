type DashboardTitleProps = {
  icon: any
  label: string
}

export const DashboardTitle: React.FC<DashboardTitleProps> = ({ icon: Icon, label }) => (
  <div className="flex justify-start items-center mb-4">
    <Icon color="#333" size="1.1rem" className="mr-2" />
    <p className="font-bold text-gray-600 text-lg">{label}</p>
  </div>
)
