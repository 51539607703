import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

type TabsProps = {
  header: string[]
  selectedIndex?: number
  items: { Component?: React.ReactNode; href?: string }[]
  isEnabled?: boolean
}

export const Tabs: React.FC<TabsProps> = ({ header, selectedIndex = 0, items, isEnabled = true }) => {
  const [keySelected, setKeySelected] = useState(selectedIndex)

  const history = useHistory()

  const handleClick = (index: number) => {
    const item = items[index]

    if (item?.Component) {
      return setKeySelected(index)
    } else if (item?.href) {
      return history.push(item.href)
    }
  }

  useEffect(() => {
    setKeySelected(selectedIndex)
  }, [selectedIndex])

  return (
    <div className="w-full">
      <div className="flex flex-wrap border-b border-gray-100">
        {header.map((header, headerIndex) => (
          <button
            key={headerIndex}
            type="button"
            onClick={() => (isEnabled ? handleClick(headerIndex) : {})}
            className={classNames(
              'w-full md:w-auto text-left py-4 px-4 border-b-2 border-transparent',
              { 'text-gray-500': !isEnabled && headerIndex !== keySelected },
              { 'border-black text-black': headerIndex === keySelected }
            )}
          >
            {header}
          </button>
        ))}
      </div>

      {items.map((item, index) => (
        <div key={index} style={{ display: index === keySelected ? 'block' : 'none' }}>
          {item.Component}
        </div>
      ))}
    </div>
  )
}
