import { DateTime } from 'luxon'

import { ISalePaymentMethod } from 'interfaces'
import { NumberHelper } from 'helpers'

const methodOptions = [
  { label: 'Boleto', value: 'BOLETO' },
  { label: 'PIX', value: 'PIX' },
  { label: 'Cartão de Crédito', value: 'CREDIT_CARD' },
]

const payloadToForm = (salePaymentMethods: ISalePaymentMethod[]) =>
  salePaymentMethods.map((salePaymentMethod) => ({
    id: salePaymentMethod.id,
    name: SalePaymentMethodHelper.formatToOption(salePaymentMethod),
    installment: salePaymentMethod.installment,
    dueAt: DateTime.fromFormat(salePaymentMethod.dueAt, 'yyyy-MM-dd').toJSDate(),
    value: NumberHelper.formatToBRL(salePaymentMethod.value),
  }))

const findMethodByBillingType = (billingType: string) => methodOptions.find(({ value }) => value === billingType)

const formatToOption = (salePaymentMethod: ISalePaymentMethod) =>
  findMethodByBillingType(salePaymentMethod.billingType) || methodOptions[0]

const dueAtFormatToPayload = (dueAt: Date) => DateTime.fromJSDate(dueAt).toFormat('yyyy-MM-dd')

const dueAtFormatToForm = (dueAt: string) => DateTime.fromFormat(dueAt, 'yyyy-MM-dd').toJSDate()

export const SalePaymentMethodHelper = {
  methodOptions,
  payloadToForm,
  findMethodByBillingType,
  formatToOption,
  dueAtFormatToPayload,
  dueAtFormatToForm,
}
