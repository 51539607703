import { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { toast } from 'react-toastify'

import { BrasilAPIServices } from 'services'
import { IBrasilApiAddress, IBrasilAPICompany } from 'interfaces'

export const useBrasilAPI = () => {
  const [company, setCompany] = useState<IBrasilAPICompany>()
  const [address, setAddress] = useState<IBrasilApiAddress>()

  const getAddressByZipcode = useCallback(async (zipcode: string) => {
    const { status, data } = await toast.promise(BrasilAPIServices.getAddressByZipcode(zipcode), {
      pending: 'Buscando endereço apartir do CEP',
      success: 'Endereço encontrado com sucesso',
      error: 'Endereção não encontrado',
    })

    if (status === 200) {
      setAddress(data)
    }
  }, [])

  const getAddressByZipcodeDebounced = useDebouncedCallback(getAddressByZipcode, 1000)

  const getCompanyInfoByCNPJ = useCallback(async (cnpj: string) => {
    const cnpjStripped = cnpj.replace(/_/g, '').replace(/-/g, '').replace(/\./g, '').replace(/\//g, '')

    const { status, data } = await toast.promise(BrasilAPIServices.getCompanyInfoByCNPJ(cnpjStripped), {
      pending: 'Buscando dados da empresa apartir do CNPJ',
      success: 'Empresa encontrada com sucesso!',
      error: 'Empresa não encontrado',
    })

    if (status === 200) {
      setCompany(data)
    }
  }, [])

  const getCompanyInfoByCNPJDebounced = useDebouncedCallback(getCompanyInfoByCNPJ, 1000)

  return {
    address,
    company,
    getAddressByZipcode,
    getAddressByZipcodeDebounced,
    getCompanyInfoByCNPJ,
    getCompanyInfoByCNPJDebounced,
  }
}
