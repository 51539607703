import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

import { Breadcrumbs, Button, Input, TextArea } from 'components'
import { useServices } from 'hooks'
import { CreateServicesResolver } from 'validations'

export const ServicesEditPage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
  } = useForm({ resolver: CreateServicesResolver })
  const { updateServiceById, getServiceById } = useServices()

  const onSubmit = async (value: any) => {
    const response = await updateServiceById(id, value)
    if (response) {
      history.push('/servicos')
    }
  }

  useEffect(() => {
    getServiceById(id).then((response) => {
      if (response) {
        setValue('name', response.name)
        setValue('value', response.value)
        setValue('content', response.content)
        setValue('hours', response.hours)
      }
    })
  }, [getServiceById, id, setValue])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Serviços', href: '/servicos' },
          { label: 'Alterar' },
        ]}
      />

      <div className="box p-8 w-full">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8 flex flex-row items-center justify-between">
            <h1 className="flex-1">Alterar Serviço</h1>
            <div className="flex flex-row pt-4 justify-end">
              <Button className="button--primary" type="submit" isLoading={isSubmitting}>
                Salvar
              </Button>
            </div>
          </div>

          <div className="sm:grid gap-2 grid-cols-3">
            <Input control={control} name="name" label="Nome" />
            <Input control={control} name="value" label="Valor" currency />
            <Input control={control} name="hours" label="Horas" type="number" />
          </div>

          <TextArea
            control={control}
            name="content"
            label="Conteúdo"
            placeholder="Descreva com mais detalhes o que consiste esse serviço, quais são as etapas, qual é o entregável para o cliente."
          />

          <div className="flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
