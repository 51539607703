import { useEffect } from 'react'
import { useParams } from 'react-router'
import { DateTime } from 'luxon'
import {
  Font,
  PDFViewer,
  Page,
  Text,
  Link as PDFLink,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer'
import { Link } from 'react-router-dom'
import robotoNormal from 'assets/fonts/Roboto-Regular.ttf'
import robotoBold from 'assets/fonts/Roboto-Bold.ttf'
import robotoLight from 'assets/fonts/Roboto-Light.ttf'
import TraconLogoImage from '../../assets/images/tracon-logo.png'
import TraconSimbolo from '../../assets/images/tracon-simbolo.png'

import { useBudgets } from 'hooks'
import { BudgetHelper, NumberHelper } from 'helpers'
import { baseURL } from 'providers'

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoNormal }, { src: robotoBold, fontWeight: 700 }, { src: robotoLight, fontWeight: 300 }],
})

export const BudgetsPDFPage = () => {
  const { id } = useParams<{ id: string }>()

  const { budget, getBudgetById } = useBudgets()

  useEffect(() => {
    getBudgetById(id)
  }, [getBudgetById, id])

  if (!budget) return null

  const { company } = budget.user

  const textColor = budget.user.company.companyStyles?.textColor || '#ffffff'
  const primaryColor = budget.user.company.companyStyles?.primaryColor || '#460edb'
  const backgroundColor = budget.user.company.companyStyles?.backgroundColor || '#ffffff'

  const pdfStyles = StyleSheet.create({
    firstDocumentPageTitle: {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#fff',
      fontSize: 24,
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: '#fff',
      marginTop: 30,
      paddingVertical: 30,
    },
    traconLogoImage: {
      width: 341,
      height: 68,
    },
    document: {
      backgroundColor,
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: 1.5,
      fontSize: 12,
      padding: 40,
      color: textColor,
    },
    header: {
      height: 100,
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'row',
      marginHorizontal: -40,
      marginTop: -40,
      paddingHorizontal: 40,
      color: primaryColor,
      backgroundColor: '#fff',
    },
    headerLogo: {
      flex: 1,
      fontSize: 22,
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    headerInfo: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flex: 1,
      fontSize: 10,
      lineHeight: 1.2,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      color: primaryColor,
    },
    subTitle: {
      fontSize: 13,
      fontWeight: 'bold',
      color: '#333',
      paddingBottom: 10,
    },
    table: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableColumn: {
      flex: 1,
      color: '#333',
    },
    tableHeaderText: {
      borderBottom: '2px solid #f0f0f0',
      fontSize: 12,
      fontWeight: 'bold',
      color: '#666',
      paddingVertical: 2,
    },
    tableColumnText: {
      color: textColor,
      fontSize: 12,
      paddingVertical: 3,
    },
    section: {
      marginBottom: 30,
    },
    sectionHighlight: {
      backgroundColor: primaryColor,
      color: textColor,
      marginHorizontal: -20,
      paddingVertical: 20,
      paddingHorizontal: 20,
      borderRadius: 4,
    },
    budgetValue: {
      fontSize: 14,
      marginTop: 5,
      color: '#fff',
    },
    footer: {
      width: '100vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 20,
      paddingHorizontal: 40,
      textAlign: 'center',
      position: 'absolute',
      left: 0,
      bottom: 0,
      fontSize: 9,
      color: '#999',
    },
  })

  const createdAt = DateTime.fromJSDate(new Date(budget.createdAt)).toFormat('DDD', { locale: 'pt-BR' })
  const dueAt = DateTime.fromJSDate(new Date(budget.dueAt)).toFormat('DDD', { locale: 'pt-BR' })
  const budgetValue = NumberHelper.formatToBRLText(budget.value)
  const budgetValueWithDiscount = NumberHelper.formatToBRLText(
    (Number(budget.value) * (100 - Number(budget.discount))) / 100
  )
  const budgetInstallmentsValue = NumberHelper.formatToBRLText((Number(budget.value) / budget.installments).toFixed(2))
  const isThereDiscount = Number(budget.discount || 0) > 0
  const isThereInstallments = Number(budget.installments || 0) > 1
  const deadline = BudgetHelper.hoursToDays(budget.hours, company.workHoursPerDay)

  const companyWebSite = company?.website
    ? company?.website?.indexOf('http') >= 0
      ? company.website
      : `http://${company.website}`
    : '#'

  const Header = () => (
    <View fixed style={pdfStyles.header}>
      <View style={pdfStyles.headerLogo}>
        {company.companyStyles?.logoURL ? (
          <Image src={baseURL + company.companyStyles?.logoURL} />
        ) : (
          <Text>{company.fantasyName}</Text>
        )}
      </View>
      <View style={pdfStyles.headerInfo}>
        <PDFLink
          style={{ fontWeight: 'light', color: '#000', textDecoration: 'none' }}
          src={`https://wa.me/${NumberHelper.phoneTextToNumberOnly(company.cellphone)}`}
        >
          {company.cellphone}
        </PDFLink>
        <PDFLink style={{ fontWeight: 'light', color: '#000', textDecoration: 'none' }} src={`maito:${company.email}`}>
          {company.email}
        </PDFLink>
      </View>
    </View>
  )

  const Footer = () => (
    <View fixed style={pdfStyles.footer}>
      <PDFLink style={{ fontWeight: 'light', color: '#000', textDecoration: 'none' }} src={companyWebSite}>
        {company.website}
      </PDFLink>
      <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
    </View>
  )

  const Title: React.FC = ({ children }) => (
    <View
      style={{ width: '100%', paddingBottom: 10, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
    >
      <Text style={pdfStyles.title}>{children}</Text>
    </View>
  )

  const PaymentMethods = () => {
    const methods = []

    if (company.companyPaymentMethods?.boleto) methods.push('Boleto')
    if (company.companyPaymentMethods?.credit) methods.push('Cartão de Crédito')
    if (company.companyPaymentMethods?.pix) methods.push('PIX')

    methods[methods.length - 1] = `e ${methods[methods.length - 1]}`
    return <Text>{methods.join(', ')}.</Text>
  }

  const customerName = budget.customer?.fantasyName || budget.customer?.contactName || '-'

  const pdfTitle = `Proposta de orçamento - ${customerName}`

  const MyDocument = () => (
    <Document title={pdfTitle} subject="Proposta de Orçamento" author={company.fantasyName}>
      <Page
        size="A4"
        style={{
          backgroundColor: primaryColor,
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image src={TraconLogoImage} style={pdfStyles.traconLogoImage} />
      </Page>

      <Page size="A4" style={pdfStyles.document}>
        <Header />

        <View style={pdfStyles.section}>
          <Text style={{ fontSize: 26, fontWeight: 'bold', color: primaryColor, paddingBottom: 20 }}>
            Proposta de Orçamento
          </Text>

          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableColumn}>
              <Text style={{ fontWeight: 'bold' }}>Criado em</Text>
              <Text style={pdfStyles.tableColumnText}>{createdAt}</Text>
            </View>
            <View style={pdfStyles.tableColumn}>
              <Text style={{ fontWeight: 'bold' }}>Válido até</Text>
              <Text style={pdfStyles.tableColumnText}>{dueAt}</Text>
            </View>
            <View style={pdfStyles.tableColumn}>
              <Text style={{ fontWeight: 'bold' }}>Cliente</Text>
              <Text style={pdfStyles.tableColumnText}>
                {customerName} - {budget.customer.cellphone || budget.customer.phone}
              </Text>
            </View>
          </View>
        </View>

        {budget.goal && (
          <View style={pdfStyles.section}>
            <Title>Objetivo</Title>
            <Text style={{ textAlign: 'justify' }}>{budget.goal}</Text>
          </View>
        )}
        <Footer />
      </Page>

      <Page size="A4" style={pdfStyles.document}>
        <Header />
        <View style={pdfStyles.section}>
          <Title>Serviços</Title>
          {budget.budgetServices.map((budgetService, budgetServiceIndex) => (
            <View key={budgetServiceIndex} style={{ marginBottom: 15 }}>
              <Text style={pdfStyles.subTitle}>{budgetService.service.name}</Text>
              <Text style={{ textAlign: 'justify' }}>{budgetService.content}</Text>
            </View>
          ))}
        </View>
        <Footer />
      </Page>

      <Page size="A4" style={pdfStyles.document}>
        <Header />
        <View style={pdfStyles.section}>
          <Title>Prazo</Title>
          <Text>
            Tempo aproximado da entrega: <Text style={{ fontWeight: 'bold' }}>{deadline}</Text>
          </Text>
        </View>

        <View style={[pdfStyles.section, pdfStyles.sectionHighlight]}>
          <Text style={[pdfStyles.title, { color: '#fff' }]}>Investimento</Text>
          <Text style={pdfStyles.budgetValue}>
            {isThereInstallments ? (
              <Text>
                {budget.installments} parcelas de {budgetInstallmentsValue}
              </Text>
            ) : isThereDiscount ? (
              budgetValueWithDiscount
            ) : (
              budgetValue
            )}
          </Text>
        </View>

        <View style={pdfStyles.section}>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableColumn}>
              <Text style={pdfStyles.tableHeaderText}>Formas de Pagamento</Text>
              <Text style={pdfStyles.tableColumnText}>Total</Text>
              {isThereDiscount && (
                <Text style={pdfStyles.tableColumnText}>À vista ({budget.discount}% de desconto)</Text>
              )}
              {isThereInstallments && <Text style={pdfStyles.tableColumnText}>À prazo</Text>}
            </View>
            <View style={pdfStyles.tableColumn}>
              <Text style={pdfStyles.tableHeaderText}>Valor (R$)</Text>
              <Text style={pdfStyles.tableColumnText}>{budgetValue}</Text>
              {isThereDiscount && <Text style={pdfStyles.tableColumnText}>{budgetValueWithDiscount}</Text>}
              {isThereInstallments && (
                <Text style={pdfStyles.tableColumnText}>
                  {budget.installments} parcelas de {budgetInstallmentsValue}
                </Text>
              )}
            </View>
          </View>
        </View>

        <View style={pdfStyles.section}>
          <Text style={pdfStyles.subTitle}>Formas de pagamento</Text>
          <PaymentMethods />
        </View>

        <View style={pdfStyles.section}>
          <Text style={{ fontSize: 9, color: '#666' }}>
            <Text style={{ fontWeight: 'bold' }}>Obs.:</Text> A nota fiscal será enviada automaticamente para seu e-mail
            assim que o pagamento for confirmado pelo sistema.
          </Text>
        </View>

        <View style={pdfStyles.section}>
          <Title>Muito obrigado!</Title>
          <Text>Estou a sua disposição para quaisquer esclarecimentos e/ou dúvidas. Até, breve!</Text>
        </View>

        <Footer />
      </Page>

      <Page
        size="A4"
        style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Image src={TraconSimbolo} style={{ width: 204, height: 167 }} />
      </Page>
    </Document>
  )

  return (
    <div className="flex">
      <div className="w-full max-w-md p-8">
        <p className="font-bold text-gray-700">{budget.customer.fantasyName || budget.customer.contactName}</p>
        <h1 className="py-2">{budget.title}</h1>
        <p className="font-bold text-gray-700 text-lg mb-8">{budgetValue}</p>

        <PDFDownloadLink className="button button--primary mt-2" document={<MyDocument />} fileName={budget.title}>
          {({ loading }) => (loading ? 'Carregando orçamento...' : 'Download do PDF')}
        </PDFDownloadLink>
        <Link to="/orcamentos" className="button button--light mt-2">
          Voltar
        </Link>
      </div>

      <div className="flex-1">
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
          <MyDocument />
        </PDFViewer>
      </div>
    </div>
  )
}
