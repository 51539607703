import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useController } from 'react-hook-form'
import get from 'lodash.get'

type EditorProps = {
  control: any
  name: string
  label?: string
  showLabel?: boolean
}

export const Editor: React.FC<EditorProps> = ({ control, name, label = 'Conteúdo', showLabel = true }) => {
  const {
    field: { value, onChange, onBlur },
    formState: { errors },
  } = useController({ control, name })

  const errorMessage = get(errors, `${name}.message`)

  return (
    <>
      {showLabel && (
        <label htmlFor={name} className="input-label">
          {label}
        </label>
      )}
      <CKEditor
        config={{
          toolbar: [
            'heading',
            '|',
            'fontfamily',
            'fontsize',
            '|',
            'alignment',
            '|',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'subscript',
            'superscript',
            '|',
            'outdent',
            'indent',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'code',
            'codeBlock',
            '|',
            'insertTable',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        }}
        editor={ClassicEditor}
        data={value}
        onBlur={onBlur}
        onChange={(event: any, editor: any) => {
          const data = editor.getData()
          onChange(data)
        }}
      />
      {errorMessage && <p className="input-text-error">{errorMessage}</p>}
    </>
  )
}
