import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateBoardsSchema = yup
  .object({
    budgetId: yup
      .object({
        label: yup.string(),
        value: yup.string().required('Selecione um orçamento'),
      })
      .required('Selecione um orçamento'),
    name: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Informe um nome'),
    dueAt: yup.string().required('Informe a data de vencimento'),
  })
  .required()
export const CreateBoardsResolver = yupResolver(CreateBoardsSchema)

const CreateCardSchema = yup
  .object({
    name: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Informe o nome do card'),
  })
  .required()
export const CreateCartResolver = yupResolver(CreateCardSchema)

const CreateSectionSchema = yup
  .object({
    name: yup.string().required('Informe o nome da nova seção'),
  })
  .required()
export const CreateSectionResolver = yupResolver(CreateSectionSchema)
