import React, { HTMLInputTypeAttribute } from 'react'
import { useController } from 'react-hook-form'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'
import get from 'lodash.get'

type InputProps = {
  mask?: string
  currency?: Boolean
  type?: HTMLInputTypeAttribute
  name: string
  showLabel?: boolean
  label?: string
  placeholder?: string
  control: any
  AppendComponent?: React.ReactNode
  helperText?: string
  autoFocus?: boolean
}

export const Input: React.FC<InputProps> = ({
  type = 'text',
  name,
  label,
  showLabel = true,
  placeholder,
  control,
  mask,
  currency,
  AppendComponent,
  helperText,
  autoFocus = false,
}) => {
  const {
    field,
    formState: { errors },
  } = useController({ control, name })

  const errorMessage = get(errors, `${name}.message`)

  const checkboxProps = type === 'checkbox' ? { checked: !!field.value } : {}

  const renderInput = () => (
    <>
      {mask ? (
        <InputMask mask={mask} value={field.value} onChange={field.onChange} autoFocus={autoFocus}>
          {(inputProps: any) => (
            <input {...inputProps} type={type} id={name} placeholder={placeholder} className="input" />
          )}
        </InputMask>
      ) : currency ? (
        <NumberFormat
          id={name}
          thousandSeparator="."
          thousandsGroupStyle="thousand"
          decimalSeparator=","
          decimalScale={2}
          className="input"
          placeholder={placeholder || label}
          value={field.value}
          onChange={field.onChange}
          autoFocus={autoFocus}
        />
      ) : (
        <input
          {...field}
          type={type}
          id={name}
          placeholder={placeholder || label}
          className="input"
          {...checkboxProps}
          autoFocus={autoFocus}
        />
      )}
      {helperText && <p className="mb-4 text-xs text-gray-600">{helperText}</p>}
    </>
  )

  return (
    <div className="block w-full">
      {showLabel && (
        <label htmlFor={name} className="input-label">
          {label || placeholder}
        </label>
      )}

      <div className="flex flex-row flex-wrap items-start">
        <div className="flex-1">{renderInput()}</div>
        {AppendComponent && AppendComponent}
      </div>

      {errorMessage && <p className="input-text-error">{errorMessage}</p>}
    </div>
  )
}
