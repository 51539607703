import { useForm } from 'react-hook-form'
import { FaCheck, FaTimes } from 'react-icons/fa'

import { Button, Input } from 'components'
import { CreateSectionResolver } from 'validations'
import { useBoards } from 'hooks'
import { IStoreBoardSectionForm } from 'interfaces'

type NewBoardSectionFormProps = {
  boardId: string
  onClose: () => void
  onSave: () => void
}

export const NewBoardSectionForm: React.FC<NewBoardSectionFormProps> = ({ boardId, onClose, onSave }) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ resolver: CreateSectionResolver })

  const { createNewBoardSection } = useBoards()

  const onSubmit = async (data: IStoreBoardSectionForm) => {
    await createNewBoardSection(boardId, data)
    onSave && onSave()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input autoFocus control={control} name="name" showLabel={false} placeholder="Informe o título da seção" />

      <div className="flex">
        <Button type="submit" isLoading={isSubmitting} className="button--small button--full bg-primary text-white">
          <FaCheck color="#fff" className="mr-2" />
          Salvar
        </Button>
        <button type="button" className="p-2 ml-2" onClick={() => onClose()}>
          <FaTimes color="#666" />
        </button>
      </div>
    </form>
  )
}
