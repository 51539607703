import classNames from 'classnames'
import { useState } from 'react'
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'
import { Animated } from 'react-animated-css'

type AccordionProps = {
  items?: { label: string; Component: React.ReactNode }[]
  selectedIndex?: number
}

export const Accordion: React.FC<AccordionProps> = ({ items, selectedIndex }) => {
  const [keySelected, setKeySelected] = useState<number | undefined>(selectedIndex)

  if (!items) return null

  return (
    <div className="w-full border border-gray-100 rounded-md overflow-hidden">
      {items.map((item, index) => {
        const isSelected = index === keySelected
        const isLastItem = items.length - 1 === index

        return (
          <div key={index} className={classNames({ 'border-b border-gray-100': !isLastItem })}>
            <button
              type="button"
              className={classNames('w-full p-4 flex items-center justify-between cursor-pointer', {
                'bg-gray-50': isSelected,
              })}
              onClick={() => setKeySelected((prev) => (prev === index ? undefined : index))}
            >
              <p>{item.label}</p>

              {isSelected ? <FaArrowDown color="#000" /> : <FaArrowUp color="#999" />}
            </button>

            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={250}
              animationOutDuration={250}
              isVisible={isSelected}
              animateOnMount={false}
            >
              {isSelected && <div className="p-4 border-t border-gray-100"> {items[index]?.Component}</div>}
            </Animated>
          </div>
        )
      })}
    </div>
  )
}
