const setItem = (key: string, value: any) => {
  value = value || ''
  window.localStorage.setItem(key, JSON.stringify(value))
}

const getItem = (key: string) => {
  const item = window.localStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
}

const removeItem = (key: string) => {
  window.localStorage.removeItem(key)
}

const clear = () => {
  window.localStorage.clear()
}

export const StorageHelper = {
  setItem,
  getItem,
  removeItem,
  clear,
}
