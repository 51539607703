import { IStoreCompanies, IStoreCompaniesForm, IUpdateCompanies, IUpdateCompaniesForm } from 'interfaces'

const formToStorePayload = (data: IStoreCompaniesForm): IStoreCompanies => ({
  ...data,
  companyType: data?.companyType?.value,
})

const formToUpdatePayload = (data: IUpdateCompaniesForm): IUpdateCompanies => ({
  ...data,
  companyType: data?.companyType?.value,
  companyPaymentMethods: {
    id: data.companyPaymentMethods.id,
    boleto: Number(data.companyPaymentMethods.boleto),
    pix: Number(data.companyPaymentMethods.pix),
    credit: Number(data.companyPaymentMethods.credit),
  },
})

const bussinesTypesOptions = [
  { label: 'MEI', value: 'MEI' },
  { label: 'Limitada', value: 'LIMITED' },
  { label: 'Individual', value: 'INDIVIDUAL' },
  { label: 'Associação', value: 'ASSOCIATION' },
]

export const CompanyHelper = {
  bussinesTypesOptions,
  formToStorePayload,
  formToUpdatePayload,
}
