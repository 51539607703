import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { CompaniesServices } from 'services'
import { ICompanies, IFilterCompanies, IPagination, IStoreCompanies, IUpdateCompanies } from 'interfaces'

export const useCompanies = () => {
  const [companies, setCompanies] = useState<IPagination<ICompanies>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllCompanies = useCallback(async (params?: IFilterCompanies) => {
    const { status, data } = await CompaniesServices.getAll(params)

    if (status === 200) {
      setCompanies(data)
    }
  }, [])

  const getCompaniesById = useCallback(async (id: string) => {
    const { status, data } = await CompaniesServices.getById(id)

    if (status === 200) {
      return data
    }
  }, [])

  const createNewCompanies = useCallback(async (company: IStoreCompanies) => {
    const { status, data } = await toast.promise(CompaniesServices.createNew(company), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateCompaniesById = useCallback(async (id: string, company: IUpdateCompanies) => {
    const { status, data } = await toast.promise(CompaniesServices.updateById(id, company), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const deleteCompaniesById = useCallback(async (id: string) => {
    await toast.promise(CompaniesServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Empresa excluída com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  const uploadLogo = useCallback(async (id: string, file: any) => {
    await toast.promise(CompaniesServices.uploadLogo(id, file), {
      pending: 'Enviando logo...',
      success: 'Logo atualizada com sucesso!',
      error: 'Houve um erro inesperado, por favor tente novamente!',
    })
  }, [])

  return {
    companies,
    getAllCompanies,
    getCompaniesById,
    createNewCompanies,
    updateCompaniesById,
    deleteCompaniesById,
    uploadLogo,
  }
}
