import { Routes } from 'routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


import { Context } from './contexts'


export const App = () => {
  return (
    <Context>
      <ToastContainer autoClose={2000} />
      <Routes />
    </Context>
  )
}
