import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaTrello } from 'react-icons/fa'

import { useBoards } from 'hooks'
import { Breadcrumbs, Pagination, SearchBar, Table } from 'components'

export const BoardsPage = () => {
  const [params, setParams] = useState<{ [key: string]: any }>()

  const history = useHistory()
  const { boards, getAllBoards, deleteBoardById } = useBoards()

  const handleDeleteItem = useCallback(
    (id: string) => {
      deleteBoardById(id).then(() => getAllBoards(params))
    },
    [deleteBoardById, getAllBoards, params]
  )

  useEffect(() => {
    getAllBoards(params)
  }, [getAllBoards, params])

  return (
    <div className="p-8">
      <Breadcrumbs links={[{ label: 'Dashboard', href: '/dashboard' }, { label: 'Boards' }]} />

      <div className="box p-8 w-full">
        <h1 className="mb-8 flex items-center">
          <FaTrello color="#460edb" size="1.4rem" className="mr-2" />
          Boards
        </h1>

        <div className="w-full flex flex-row flex-wrap items-start justify-between mb-4">
          <div className="flex-1 max-w-lg">
            <SearchBar onSubmit={({ search }) => setParams({ search })} />
          </div>
          <Link className="button button--primary" to="/boards/adicionar">
            Adicionar
          </Link>
        </div>

        <Table
          headers={[
            { key: 'name', label: 'Nome' },
            { key: 'dueAt', label: 'Vencimento', width: 150 },
            { key: 'createdAt', label: 'Cadastro', width: 150 },
          ]}
          actions={[{ label: 'Excluir', onPress: (item) => handleDeleteItem(item.id) }]}
          onClick={(item) => history.push(`/boards/visualizar/${item.id}`)}
          data={boards.items}
        />

        <Pagination
          currentPage={boards.currentPage}
          totalPages={boards.totalPages}
          onClick={(currentPage: number) => setParams((prev) => ({ ...prev, currentPage }))}
        />
      </div>
    </div>
  )
}
