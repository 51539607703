type BigNumberCardProps = {
  Icon: any
  iconColor: string
  label: string
  number: number | string
}

export const BigNumberCard: React.FC<BigNumberCardProps> = ({ Icon, iconColor = 'green', label, number }) => (
  <div className="box p-8 flex flex-col justify-center">
    <div className="flex items-center">
      {Icon && <Icon color={iconColor} className="mr-2" />}
      <span className="font-bold py-1 text-gray-600">{label}</span>
    </div>
    <h1>{number}</h1>
  </div>
)
