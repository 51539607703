import { Link, useHistory } from 'react-router-dom'
import { FaChartBar } from 'react-icons/fa'
import { GiHamburgerMenu } from 'react-icons/gi'
import classNames from 'classnames'

import { useAuthContext } from 'contexts/AuthContext'
import { useSidebarContext } from 'contexts/SidebarContext'

export type NavProps = {}

export const Nav: React.FC<NavProps> = () => {
  const { setUser } = useAuthContext()
  const { setIsVisible } = useSidebarContext()
  const history = useHistory()
  const match = (path: string) => history.location.pathname.startsWith(path)

  const handleSignOut = () => {
    setUser(undefined)
    history.push('/entrar')
  }

  return (
    <nav className="h-16 w-full px-4 flex flex-row bg-primary items-center justify-between drop-shadow-md">
      <ul className="flex-1 flex">
        <li>
          <span className="nav-item center" onClick={() => setIsVisible((prev) => !prev)}>
            <GiHamburgerMenu color="#fff" size="1.1rem" className="" />
          </span>
        </li>
        <li>
          <Link to="/dashboard" className={classNames('nav-item', { 'nav-item--active': match('/dashboard') })}>
            <FaChartBar color="#fff" size="1.1rem" className="mr-2" />
            Dashboard
          </Link>
        </li>
      </ul>

      <ul className="flex-1 flex justify-end">
        <li>
          <button type="button" className="button button--light" onClick={handleSignOut}>
            <span className="text-white">Sair</span>
          </button>
        </li>
      </ul>
    </nav>
  )
}
