import React, { useState } from 'react'
import { FaCheck, FaClock, FaExternalLinkAlt, FaTimes } from 'react-icons/fa'

import { Table } from 'components'
import { SaleInstallmentsStatusModal } from 'pages'
import { ISaleInstallment, ISalePaymentMethod } from 'interfaces'
import { NumberHelper, SaleInstallmentHelper } from 'helpers'
import { PaymentStatusEnum } from 'enum/payment-status.enum'

type SaleInstallmentsTabProps = {
  salePaymentMethods: ISalePaymentMethod[]
  onInstallmentUpdate?: () => void
}

export const SaleInstallmentsTab: React.FC<SaleInstallmentsTabProps> = ({
  salePaymentMethods,
  onInstallmentUpdate,
}) => {
  const [saleInstallment, setSaleInstallment] = useState<ISaleInstallment>()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const handleInstallmentStatusButton = (installment: ISaleInstallment) => {
    setIsModalVisible(true)
    setSaleInstallment(installment)
  }

  const handleOnModalSubmit = () => {
    setIsModalVisible(false)
    if (onInstallmentUpdate) onInstallmentUpdate()
  }

  const renderStatus = (status: string) => {
    const newStatus = SaleInstallmentHelper.formatStatus(status)

    switch (status) {
      case PaymentStatusEnum.CONFIRMED:
      case PaymentStatusEnum.RECEIVED:
      case PaymentStatusEnum.RECEIVED_IN_CASH:
        return (
          <div className="flex items-center justify-start">
            <FaCheck color="green" size="1rem" className="mr-2" />
            {newStatus}
          </div>
        )
      case PaymentStatusEnum.OVERDUE:
        return (
          <div className="flex items-center justify-start">
            <FaTimes color="red" size="1rem" className="mr-2" />
            {newStatus}
          </div>
        )
      case PaymentStatusEnum.PENDING:
        return (
          <div className="flex items-center justify-start">
            <FaClock color="orange" size="1rem" className="mr-2" />
            {newStatus}
          </div>
        )
      default:
        return newStatus
    }
  }

  return (
    <>
      <SaleInstallmentsStatusModal
        installment={saleInstallment}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onSubmit={handleOnModalSubmit}
      />
      <div className="py-4">
        {salePaymentMethods.map((salePaymentMethod, salePaymentMethodIndex) => (
          <div key={salePaymentMethodIndex}>
            <Table
              data={salePaymentMethod.saleInstallments}
              headers={[
                { key: 'method', label: 'Forma' },
                { key: 'dueAt', label: 'Vence em', width: 100 },
                { key: 'paidAt', label: 'Pago em', width: 100 },
                { key: 'status', label: 'Status' },
                { key: 'value', label: 'Valor (R$)' },
                { key: 'netValue', label: 'Recebido (R$)', width: 150 },
                { key: 'paymentLink', label: 'Link', width: 50 },
              ]}
              renderItem={(item: ISaleInstallment) => ({
                ...item,
                method: salePaymentMethod.billingType,
                value: NumberHelper.formatToBRL(item.value),
                status:
                  item.status === PaymentStatusEnum.PENDING ? (
                    <button
                      className="button button--small button--light"
                      onClick={() => handleInstallmentStatusButton(item)}
                    >
                      {renderStatus(item.status)}
                    </button>
                  ) : (
                    <span>{renderStatus(item.status)}</span>
                  ),
                netValue: item.paidAt ? NumberHelper.formatToBRL(item.netValue) : '',
                paymentLink: (
                  <a
                    className="button button--small button--light"
                    href={
                      salePaymentMethod.billingType === 'PIX' ? item.invoiceUrl : item.bankSlipUrl || item.invoiceUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Abrir link de pagamento"
                  >
                    <FaExternalLinkAlt color="gray" />
                  </a>
                ),
              })}
            />
          </div>
        ))}
      </div>
    </>
  )
}
