import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PaymentMethodsConfig } from 'config'

const select = (message: string) =>
  yup.object({
    label: yup.string().required(message),
    value: yup.string().required(message),
  })

const CreateSalesSchema = yup.object({
  budgetId: select('Selecione um orçamento').nullable(),
  salePaymentMethods: yup
    .array()
    .of(
      yup.object({
        billingType: yup
          .object({
            label: yup.string().nullable().required('Selecione a forma de pagamento'),
            value: yup
              .string()
              .nullable()
              .oneOf(PaymentMethodsConfig.methodsList.map(({ value }) => value))
              .required('Selecione a forma de pagamento'),
          })
          .nullable()
          .required('Informe a forma de pagamento'),
        installment: yup
          .number()
          .min(1, 'O valor deve ser maior que zero')
          .nullable()
          .required('Informe a quantidade de parcelas'),
        value: yup
          .string()
          .test('no-zero', 'O valor deve ser maior que zero', (value) => (value ? parseFloat(value) > 0 : false))
          .required('Informe o valor'),
        dueAt: yup.date().required('Informe a data'),
      })
    )
    .required('Informe a forma de pagamento'),
  saleContracts: yup
    .array()
    .of(
      yup.object({
        contractId: yup.object({
          label: yup.string(),
          value: yup.string(),
        }),
      })
    )
    .nullable(),
})

export const CreateSalesResolver = yupResolver(CreateSalesSchema)
