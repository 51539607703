import { useMemo } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { useAuthContext } from 'contexts/AuthContext'
import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes'

export const Routes = () => {
  const { user } = useAuthContext()
  const isAuthenticated = useMemo(() => user?.token, [user?.token])

  return (
    <BrowserRouter>
      <Switch>{isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}</Switch>
    </BrowserRouter>
  )
}
