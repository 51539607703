import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Tabs } from 'components'
import { SignUpUser, SignUpCompany } from 'pages'

export const SignUpPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div className="w-full min-h-screen flex items-stretch flex-wrap flex-wrap-reverse">
      <div className="w-full lg:w-1/3 p-8 center flex-col bg-primary text-white text-center">
        <div className="mb-6">
          <h1 className="text-white">Já tem uma conta?</h1>
          <p className="text-lg">Acesse sua conta agora mesmo</p>
        </div>

        <Link to="/entrar" className="button button--light">
          <span className="text-white">Acesse sua conta</span>
        </Link>
      </div>

      <div className="w-full lg:flex-1 p-8 center flex-col">
        <div className="w-full max-w-xl">
          <h1 className="text-center pt-2 pb-4">Crie sua conta</h1>

          <Tabs
            isEnabled={false}
            selectedIndex={tabIndex}
            header={['1. Usuário', '2. Conta']}
            items={[
              {
                Component: <SignUpUser onSuccess={() => setTabIndex(1)} />,
              },
              {
                Component: <SignUpCompany />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}
