import React, { useState, createContext, useContext, useEffect } from 'react'

import { AuthHelper, StorageHelper } from 'helpers'
import { IUser } from 'interfaces'

const defaultValue = StorageHelper.getItem('@erp/user') || {}

interface IAuthContext {
  user: Partial<IUser>
  token: string
}

const AuthContext =
  createContext<{ user?: IAuthContext; setUser: (data: IAuthContext | undefined) => void }>(defaultValue)

const useAuthContext = () => useContext(AuthContext)

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IAuthContext | undefined>(defaultValue)

  useEffect(() => {
    StorageHelper.setItem('@erp/user', user)
    if (user) {
      AuthHelper.setBearerToken(user.token)
    }
  }, [user])

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>
}

export { useAuthContext, AuthProvider }
