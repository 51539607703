import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useSales } from 'hooks'

export const SaleServiceContractPage = () => {
  const { saleId, contractId } = useParams<{ saleId: string; contractId: string }>()

  const { getContract } = useSales()

  const [html, setHtml] = useState<string | undefined>()

  useEffect(() => {
    getContract(saleId, contractId).then((res) => {
      setHtml(res)

      window.print()
    })
  }, [contractId, getContract, saleId])

  if (!html) return null

  return <div dangerouslySetInnerHTML={{ __html: html }}></div>
}
