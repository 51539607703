import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { ForgotPasswordResolver } from 'validations'
import { Button, Input } from 'components'
import { useAuth } from 'hooks'

export const ForgotPassword = () => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({ resolver: ForgotPasswordResolver })
  const { forgotPassword } = useAuth()
  const history = useHistory()

  const onSubmit = useCallback(
    async (data) => {
      await forgotPassword(data.email)
      history.push('/')
    },
    [history, forgotPassword]
  )

  return (
    <div className="w-full center min-h-screen">
      <div className="w-full max-w-xl p-8 center">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-center pt-2 pb-4">
            <h1>Recuperar Senha</h1>
            <p>Informe o seu e-mail para que seja enviado um e-mail de recuperação de senha para ele.</p>
          </div>

          <Input control={control} name="email" placeholder="E-mail" />

          <div className="w-full center flex-col pt-4">
            <Button type="submit" className="button--primary" isLoading={isSubmitting}>
              RECUPERAR
            </Button>
            <div className="mt-4">
              <Link to="/entrar" className="link">
                Voltar para o login
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
