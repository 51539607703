import classNames from 'classnames'

type PaginationProps = {
  currentPage: number
  totalPages: number
  onClick?: (currentPage: number) => void
}

export const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onClick }) => {
  const pages = []
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i)
  }

  return (
    <div className="center mt-4">
      {pages.map((page, index) => (
        <button
          key={index}
          type="button"
          onClick={() => onClick && onClick(page)}
          className={classNames('rounded-md py-2 px-4 cursor-pointer bg-white', { 'bg-primary text-white': currentPage === page })}
        >
          {page}
        </button>
      ))}
    </div>
  )
}
