import { ICustomer } from 'interfaces'

const formatToOptions = (customers: ICustomer[]) =>
  customers.map((customer) => ({
    label: customer?.fantasyName || customer?.contactName || '',
    value: customer.id,
  }))

export const CustomerHelper = {
  formatToOptions,
}
