import { AxiosResponse } from 'axios'

import { IFilterSale, IPagination, ISale, IStoreSale, IUpdateSale, IUpdateSaleInstallment } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterSale) => api.get<IPagination<ISale>>('/api/v1/sales', { params })

const getById = (id: string) => api.get<ISale>(`/api/v1/sales/${id}`)

const createNew = (sale: IStoreSale) => api.post<IStoreSale, AxiosResponse<ISale>>('/api/v1/sales', sale)

const updateById = (id: string, sale: IUpdateSale) =>
  api.patch<IUpdateSale, AxiosResponse<ISale>>(`/api/v1/sales/${id}`, sale)

const showContract = (saleId: string, contractId: string) =>
  api.get<string>(`/api/v1/sales/${saleId}/contract/${contractId}`)

const showInstallments = (id: string) => api.get<ISale>(`/api/v1/sales/${id}/installments`)

const deleteById = (id: string) => api.delete<void>(`/api/v1/sales/${id}`)

const updateSaleInstallmentById = (saleInstallmentId: string, data: IUpdateSaleInstallment) =>
  api.patch<IUpdateSaleInstallment, AxiosResponse<void>>(`/api/v1/sales/installment/${saleInstallmentId}`, data)

export const SalesServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
  showContract,
  showInstallments,
  updateSaleInstallmentById,
}
