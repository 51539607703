const formatToBRLText = (currency?: string | number) =>
  currency !== undefined
    ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(currency))
    : ''

const formatBRLTextToNumber = (price?: string) => (price ? Number(formatToUSD(price).replace(/R\$/g, '')) : 0)

const formatToBRL = (currency?: string) => (currency ? String(currency).replace(/\./g, ',') : '')

const formatToUSD = (currency?: string) => (currency ? String(currency).replace(/\./g, '').replace(/,/g, '.') : '')

const phoneTextToNumberOnly = (phoneText: string) =>
  phoneText.replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/-/g, '')

export const NumberHelper = {
  formatToBRLText,
  formatBRLTextToNumber,
  formatToBRL,
  formatToUSD,
  phoneTextToNumberOnly,
}
