import { NumberHelper } from 'helpers'
import { IBudget, IStoreBudget, IStoreBudgetForm, IUpdateBudget, IUpdateBudgetForm } from 'interfaces'
import { DateTime } from 'luxon'
import { DateTime as DateTimeBussinesDay } from 'luxon-business-days'

const dt = DateTimeBussinesDay.local()
const holidayMatchers = [
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 1, day: 1 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 4, day: 2 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 4, day: 21 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 5, day: 1 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 9, day: 7 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 9, day: 12 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 11, day: 2 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 11, day: 15 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 12, day: 24 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 12, day: 25 }),
  (inst: DateTime) => +inst === +DateTime.fromObject({ month: 12, day: 31 }),
]
dt.setupBusiness({ holidayMatchers })

const formatToDisplay = (budgetList: IBudget[]) =>
  budgetList.map((budget) => ({
    ...budget,
    value: NumberHelper.formatToBRLText(budget.value),
  }))

const payloadToForm = (budget: IBudget) => ({
  ...budget,
  value: NumberHelper.formatToBRL(budget.value),
})

const formToPayload = (budget: IStoreBudgetForm): IStoreBudget => ({
  title: budget.title,
  customerId: budget.customerId.value.toString(),
  value: NumberHelper.formatToUSD(budget.value),
  goal: budget.goal,
  discount: budget.discount,
  installments: budget.installments,
  dueAt: DateTime.fromJSDate(new Date(budget.dueAt)).toFormat('yyyy-MM-dd'),
  hours: budget.hours,
  budgetServices: budget.budgetServices.map((budgetService) => ({
    serviceId: budgetService.serviceId.value,
    content: budgetService.content,
  })),
})

const updateFormToPayload = (budget: IUpdateBudgetForm): IUpdateBudget => ({
  title: budget.title,
  customerId: budget.customerId.value.toString(),
  value: NumberHelper.formatToUSD(budget.value),
  goal: budget.goal,
  discount: budget.discount,
  installments: budget.installments,
  dueAt: DateTime.fromJSDate(new Date(budget.dueAt)).toFormat('yyyy-MM-dd'),
  hours: budget.hours,
  budgetServices: budget.budgetServices.map((budgetService) => ({
    id: budgetService.id,
    budgetId: budgetService.budgetId,
    serviceId: budgetService.serviceId.value,
    content: budgetService.content,
  })),
})

const hoursToDays = (hours: number, workHoursPerDay: number = 8) => {
  const insurance = 1.2
  const days = Math.ceil((hours / workHoursPerDay) * insurance)
  console.log('file: budget.ts:68 ~ hoursToDays ~ days', days)

  let deadline = DateTime.now()
  deadline = deadline.plus({ days })

  const relative = deadline.toRelativeCalendar({ locale: 'pt-BR' })
  const date = deadline.toFormat('dd/MM/yyyy', { locale: 'pt-BR' })

  return `${date} (${relative})`
}

const formatDueDate = (dueAt: string) => DateTime.fromFormat(dueAt, 'yyyy-MM-dd').toFormat('D', { locale: 'pt-BR' })

const formatCreatedAt = (createdAt: string) => DateTime.fromISO(createdAt).toFormat('D', { locale: 'pt-BR' })

const formatToOptions = (items: IBudget[]) =>
  items.map(({ id, title, value, customer }) => ({
    label: `${title} / ${value} / ${customer?.fantasyName || customer?.contactName}`,
    value: id,
  }))

export const BudgetHelper = {
  formatToDisplay,
  payloadToForm,
  formToPayload,
  updateFormToPayload,
  hoursToDays,
  formatDueDate,
  formatCreatedAt,
  formatToOptions,
}
