import { IBudgetService, IStoreBudgetServiceForm, IUpdateBudgetServiceForm } from 'interfaces'

const formatPayloadToForm = (budget: IBudgetService): IStoreBudgetServiceForm => ({
  serviceId: { label: budget.service.name, value: budget.service.id },
  content: budget.content,
})

const formatPayloadToUpdateForm = (budget: IBudgetService): IUpdateBudgetServiceForm => ({
  id: budget.id,
  budgetId: budget.budgetId,
  serviceId: { label: budget.service.name, value: budget.service.id },
  content: budget.content,
})

export const BudgetServiceHelper = {
  formatPayloadToForm,
  formatPayloadToUpdateForm,
}
