import { useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { FaTimes } from 'react-icons/fa'

import { Breadcrumbs, Select, Input, TextArea, DatePicker, Tabs, Button } from 'components'
import { useBudgets, useCustomers, useServices } from 'hooks'
import { UpdateBudgetResolver } from 'validations'
import { ISelect, IUpdateBudgetForm } from 'interfaces'
import { BudgetServiceHelper, CustomerHelper, NumberHelper, ServiceHelper } from 'helpers'
import { DateTime } from 'luxon'

export const BudgetsEditPage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const {
    control,
    formState: { isSubmitting },
    setValue,
    handleSubmit,
  } = useForm({ resolver: UpdateBudgetResolver })
  const budgetService = useFieldArray({ control, name: 'budgetServices', keyName: 'key' })

  const { updateBudgetById, getBudgetById } = useBudgets()
  const { customers, getAllCustomers, getAllCustomersOptionsDebounced } = useCustomers()
  const { services, getAllServices, getServiceById } = useServices()

  const onSubmit = async (value: IUpdateBudgetForm) => {
    const response = await updateBudgetById(id, value)
    if (response) {
      history.push('/orcamentos')
    }
  }

  const handleAddBudgetService = () => {
    budgetService.append({ serviceId: undefined, content: undefined })
  }

  const handleDeleteBudgetItem = (index: number) => {
    budgetService.remove(index)
  }

  const handleServiceIdChange = (data: ISelect, index: number) => {
    getServiceById(data.value.toString()).then((service) => {
      budgetService.update(index, {
        serviceId: { label: data.label, value: data.value.toString() },
        content: service?.content,
      })
    })
  }

  useEffect(() => {
    Promise.all([getAllCustomers(), getAllServices()])
  }, [getAllCustomers, getAllServices])

  useEffect(() => {
    getBudgetById(id).then((budget) => {
      if (!budget) return

      setValue('title', budget.title)
      setValue('customerId', {
        label: budget.customer?.fantasyName || budget.customer?.contactName || '',
        value: budget.customerId,
      })
      setValue('value', NumberHelper.formatToBRL(budget.value))
      setValue('goal', budget.goal)
      setValue('installments', budget.installments)
      setValue('hours', budget.hours)
      setValue('dueAt', DateTime.fromFormat(budget.dueAt, 'yyyy-MM-dd').toJSDate())
      setValue('discount', budget.discount)
      setValue('budgetServices', budget.budgetServices.map(BudgetServiceHelper.formatPayloadToUpdateForm))
    })
  }, [getBudgetById, id, setValue])

  const customersOption = useMemo(() => CustomerHelper.formatToOptions(customers.items), [customers?.items])
  const servicesOptions = useMemo(() => ServiceHelper.formatToOptions(services.items), [services?.items])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Orçamentos', href: '/orcamentos' },
          { label: 'Alterar' },
        ]}
      />

      <div className="box p-8 w-full">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            header={['Dados', 'Serviços', 'Valores']}
            items={[
              {
                Component: (
                  <div className="pt-2 my-6">
                    <div className="sm:grid gap-2 grid-cols-3 mb-4">
                      <Select
                        async
                        defaultOptions={customersOption}
                        loadOptions={(search: string, callback) => {
                          getAllCustomersOptionsDebounced(callback, { search })
                        }}
                        control={control}
                        name="customerId"
                        placeholder="Cliente"
                        label="Cliente"
                      />
                      <DatePicker control={control} name="dueAt" label="Data de Vencimento" />
                    </div>

                    <Input
                      control={control}
                      name="title"
                      label="Título do orçamento"
                      placeholder="Ex.: Criar estratégia e design para o Instagram"
                    />
                    <TextArea
                      control={control}
                      name="goal"
                      label="Objetivo"
                      placeholder="Ex.: O objetivo desse orçamento é criar uma estratégia de posicionamento voltado para a persona do cliente e também desenvolver o design de 4 posts."
                    />
                  </div>
                ),
              },
              {
                Component: (
                  <div className="pt-2 my-6">
                    {budgetService.fields.map((budgetServiceItem, budgetServiceIndex) => (
                      <div key={budgetServiceItem.key}>
                        <div className="w-full mb-4 flex flex-wrap items-center justify-between">
                          <div className="flex-1 max-w-lg">
                            <Select
                              control={control}
                              options={servicesOptions}
                              name={`budgetServices.${budgetServiceIndex}.serviceId`}
                              placeholder="Serviço"
                              label="Serviço"
                              onChange={(data) => handleServiceIdChange(data, budgetServiceIndex)}
                            />
                          </div>

                          <button
                            type="button"
                            className="button button--small button--light"
                            onClick={() => handleDeleteBudgetItem(budgetServiceIndex)}
                          >
                            <FaTimes />
                          </button>
                        </div>
                        <TextArea
                          control={control}
                          name={`budgetServices.${budgetServiceIndex}.content`}
                          label="Conteúdo"
                        />
                      </div>
                    ))}

                    <button
                      type="button"
                      className="button button--small button--light mt-4 mb-4"
                      onClick={handleAddBudgetService}
                    >
                      Adicionar serviço
                    </button>
                  </div>
                ),
              },
              {
                Component: (
                  <div className="pt-2 my-6 sm:grid gap-2 grid-cols-2">
                    <Input control={control} type="number" name="hours" label="Horas de trabalho" />
                    <Input control={control} name="value" label="Valor (R$)" currency />
                    <Input control={control} type="number" name="installments" label="Parcelas" />
                    <Input control={control} type="number" name="discount" label="Desconto à vista (%)" />
                  </div>
                ),
              },
            ]}
          />

          <div className="w-full flex flex-row pt-4 justify-end">
            <Button className="button button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
