import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { FaSearch } from 'react-icons/fa'

import { Breadcrumbs, Button, Input, Select, Tabs } from 'components'
import { useBrasilAPI, useCompanies } from 'hooks'
import { UpdateCompanyResolver } from 'validations'
import { CompanyHelper } from 'helpers'
import { ICompanies, IUpdateCompaniesForm } from 'interfaces'
import { baseURL } from 'providers'

export const CompaniesEditPage = () => {
  const [company, setCompany] = useState<ICompanies>()
  const [file, setFile] = useState()
  const [isZipcodeLoading, setIsZipcodeLoading] = useState(false)
  const [isCompanyLoading, setIsCompanyLoading] = useState(false)
  const [isLogoLoading, setIsLogoLoading] = useState(false)

  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    watch,
  } = useForm({ resolver: UpdateCompanyResolver })
  const {
    address,
    company: companyFromCNPJ,
    getAddressByZipcodeDebounced,
    getCompanyInfoByCNPJDebounced,
  } = useBrasilAPI()
  const { getCompaniesById, updateCompaniesById, uploadLogo } = useCompanies()

  const cnpjWatched = watch('cnpj')
  const zipcodeWatched = watch('zipcode')

  const onSubmit = async (value: IUpdateCompaniesForm) => {
    const payload = CompanyHelper.formToUpdatePayload(value)
    const response = await updateCompaniesById(id, payload)
    if (response) {
      history.push('/empresas')
    }
  }

  const searchCompanyAddressByZipcode = useCallback(() => {
    if (zipcodeWatched) {
      const zipcodeStripped = String(zipcodeWatched)
      if (zipcodeStripped?.length >= 8) {
        setIsZipcodeLoading(true)
        getAddressByZipcodeDebounced(String(zipcodeWatched))
      }
    }
  }, [getAddressByZipcodeDebounced, zipcodeWatched])

  const searchCompanyByCNPJ = useCallback(() => {
    if (cnpjWatched && cnpjWatched?.length >= 18) {
      setIsCompanyLoading(true)
      getCompanyInfoByCNPJDebounced(cnpjWatched)
    }
  }, [getCompanyInfoByCNPJDebounced, cnpjWatched])

  const handleUploadLogo = async () => {
    setIsLogoLoading(true)
    await uploadLogo(id, file)
    setIsLogoLoading(false)
    history.push('/empresas')
  }

  useEffect(() => {
    if (address) {
      setValue('state', address.state)
      setValue('city', address.city)
      setValue('neighborhood', address.neighborhood)
      setValue('addressName', address.street)
    }
    setIsZipcodeLoading(false)
  }, [address, setValue])

  useEffect(() => {
    if (companyFromCNPJ) {
      setValue('socialName', companyFromCNPJ.razao_social)
      setValue('fantasyName', companyFromCNPJ.nome_fantasia || companyFromCNPJ.razao_social)
      setValue('zipcode', companyFromCNPJ.cep)

      let phone = companyFromCNPJ.ddd_fax || companyFromCNPJ.ddd_telefone_1 || companyFromCNPJ.ddd_telefone_2
      phone = String(phone).replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
      setValue('phone', phone)
    }
    setIsCompanyLoading(false)
  }, [companyFromCNPJ, setValue])

  useEffect(() => {
    getCompaniesById(id).then((company) => {
      if (company) {
        setCompany(company)
        setValue('personType', company?.personType || '')
        setValue('name', company.name)
        setValue('socialName', company.socialName)
        setValue('fantasyName', company.fantasyName)
        setValue('cnpj', company.cnpj)
        setValue('cpf', company.cpf)
        setValue(
          'companyType',
          CompanyHelper.bussinesTypesOptions.find(({ value }) => value === company?.companyType) || null
        )
        setValue('addressName', company.addressName)
        setValue('addressNumber', company.addressNumber)
        setValue('city', company.city)
        setValue('state', company.state)
        setValue('zipcode', company.zipcode)
        setValue('neighborhood', company.neighborhood)
        setValue('email', company.email)
        setValue('cellphone', company.cellphone)
        setValue('phone', company.phone)
        setValue('website', company.website)
        setValue('workHoursPerDay', company.workHoursPerDay)
        setValue('companyStyles.id', company?.companyStyles?.id)
        setValue('companyStyles.logoURL', company?.companyStyles?.logoURL)
        setValue('companyStyles.textColor', company?.companyStyles?.textColor)
        setValue('companyStyles.primaryColor', company?.companyStyles?.primaryColor)
        setValue('companyStyles.backgroundColor', company?.companyStyles?.backgroundColor)
        setValue('companyPaymentMethods.id', company?.companyPaymentMethods?.id)
        setValue('companyPaymentMethods.boleto', Boolean(company?.companyPaymentMethods?.boleto || 0))
        setValue('companyPaymentMethods.credit', Boolean(company?.companyPaymentMethods?.credit || 0))
        setValue('companyPaymentMethods.pix', Boolean(company?.companyPaymentMethods?.pix || 0))
      }
    })
  }, [getCompaniesById, id, setValue])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Empresas', href: '/empresas' },
          { label: 'Alterar' },
        ]}
      />

      <div className="box p-8 w-full">
        <div className="mb-8 flex flex-row items-center justify-between">
          <h1 className="flex-1">Alterar Empresa</h1>
        </div>

        <Tabs
          header={['Cadastro', 'Logo']}
          items={[
            {
              Component: (
                <form className="w-full pt-4" onSubmit={handleSubmit(onSubmit)}>
                  <div className="sm:grid gap-2 grid-cols-2">
                    <Input
                      control={control}
                      name="cnpj"
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      AppendComponent={
                        <Button
                          isLoading={isCompanyLoading}
                          className="button--light ml-2"
                          type="button"
                          onClick={searchCompanyByCNPJ}
                        >
                          <FaSearch />
                        </Button>
                      }
                    />
                    <Select
                      control={control}
                      name="companyType"
                      options={CompanyHelper.bussinesTypesOptions}
                      label="Tipo de Empresa"
                    />
                  </div>

                  <div className="sm:grid gap-2 grid-cols-2">
                    <Input control={control} name="fantasyName" placeholder="Nome Fantasia" />
                    <Input control={control} name="socialName" placeholder="Razão Social" />
                  </div>

                  <h3 className="py-2 my-2 text-gray-400">Endereço</h3>

                  <div className="w-1/3 flex items-start">
                    <Input
                      control={control}
                      name="zipcode"
                      helperText="Pesquise o endereço pelo CEP"
                      placeholder="CEP"
                      mask="99999-999"
                      AppendComponent={
                        <Button
                          isLoading={isZipcodeLoading}
                          className="button--light ml-2"
                          type="button"
                          onClick={searchCompanyAddressByZipcode}
                        >
                          <FaSearch />
                        </Button>
                      }
                    />
                  </div>

                  <div className="sm:grid gap-2 grid-cols-2">
                    <Input control={control} name="addressName" placeholder="Endereço" />
                    <Input control={control} name="addressNumber" placeholder="Número" />
                  </div>

                  <div className="sm:grid gap-2 grid-cols-3">
                    <Input control={control} name="neighborhood" placeholder="Bairro" />
                    <Input control={control} name="city" placeholder="Cidade" />
                    <Input control={control} name="state" placeholder="UF" />
                  </div>

                  <h3 className="py-2 my-2 text-gray-400">Contato</h3>

                  <div className="w-1/3">
                    <Input control={control} name="email" placeholder="E-mail" />
                  </div>

                  <div className="sm:grid gap-2 grid-cols-3">
                    <Input control={control} name="website" placeholder="Website" />
                    <Input control={control} name="cellphone" placeholder="Celular" mask="(99) 99999-9999" />
                    <Input control={control} name="phone" placeholder="Telefone" mask="(99) 9999-9999" />
                  </div>

                  <h3 className="py-2 my-2 text-gray-400">Estilização</h3>
                  <div className="sm:grid gap-2 grid-cols-3">
                    <Input control={control} name="companyStyles.textColor" type="color" label="Cor do texto" />
                    <Input control={control} name="companyStyles.backgroundColor" type="color" label="Cor de fundo" />
                    <Input control={control} name="companyStyles.primaryColor" type="color" label="Cor primária" />
                  </div>

                  <h3 className="py-2 my-2 text-gray-400">Informações</h3>
                  <div className="sm:grid gap-2 grid-cols-3">
                    <Input control={control} name="workHoursPerDay" label="Horas de trabalho por dia" />
                  </div>

                  <h3 className="py-2 my-2 text-gray-400">Formas de Pagamento</h3>
                  <div className="sm:grid gap-2 grid-cols-3">
                    <Input control={control} name="companyPaymentMethods.boleto" type="checkbox" label="Boleto" />
                    <Input
                      control={control}
                      name="companyPaymentMethods.credit"
                      type="checkbox"
                      label="Cartão de Crédito"
                    />
                    <Input control={control} name="companyPaymentMethods.pix" type="checkbox" label="PIX" />
                  </div>

                  <div className="w-full flex flex-row pt-4 justify-end">
                    <Button className="button--primary" type="submit" isLoading={isSubmitting}>
                      Salvar
                    </Button>
                  </div>
                </form>
              ),
            },
            {
              Component: (
                <div className="w-full pt-4">
                  <div className="flex">
                    <div className="px-4 center border border-gray-50 rounded-md mt-4 bg-gray-100">
                      <img src={baseURL + company?.companyStyles?.logoURL} alt={company?.fantasyName} />
                    </div>

                    <div className="px-4">
                      <label htmlFor="file" className="input-label">
                        Logo
                      </label>
                      <input
                        type="file"
                        name="companyStyles.logoURL"
                        onChange={(e: any) => setFile(e.target.files[0])}
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-row pt-4 justify-end">
                    <Button
                      isLoading={isLogoLoading}
                      className="button--primary"
                      type="button"
                      onClick={handleUploadLogo}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}
