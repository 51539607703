import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import {
  DashboardPage,
  CustomersPage,
  CompaniesPage,
  CompaniesEditPage,
  ServicesPage,
  ServicesAddPage,
  ServicesEditPage,
  ContractsPage,
  ContractsAddPage,
  ContractsEditPage,
  BudgetsPage,
  BudgetsAddPage,
  SalesEditPage,
  SaleServiceContractPage,
  SalesPage,
  SalesAddPage,
  BudgetsEditPage,
  BudgetsPDFPage,
  CustomersAddPage,
  CustomersEditPage,
  BoardsPage,
  BoardsAddPage,
  BoardsViewPage,
} from 'pages'
import { AdminLayoutRoute } from 'layouts'

export const AuthenticatedRoutes = () => (
  <BrowserRouter>
    <Switch>
      <AdminLayoutRoute exact path="/" component={DashboardPage} />
      <AdminLayoutRoute exact path="/dashboard" component={DashboardPage} />
      <AdminLayoutRoute exact path="/boards" component={BoardsPage} />
      <AdminLayoutRoute exact path="/boards/adicionar" component={BoardsAddPage} />
      <AdminLayoutRoute exact path="/boards/visualizar/:id" component={BoardsViewPage} />
      <AdminLayoutRoute exact path="/empresas" component={CompaniesPage} />
      <AdminLayoutRoute exact path="/empresas/alterar/:id" component={CompaniesEditPage} />
      <AdminLayoutRoute exact path="/clientes" component={CustomersPage} />
      <AdminLayoutRoute exact path="/clientes/adicionar" component={CustomersAddPage} />
      <AdminLayoutRoute exact path="/clientes/alterar/:id" component={CustomersEditPage} />
      <AdminLayoutRoute exact path="/servicos" component={ServicesPage} />
      <AdminLayoutRoute exact path="/servicos/adicionar" component={ServicesAddPage} />
      <AdminLayoutRoute exact path="/servicos/alterar/:id" component={ServicesEditPage} />
      <AdminLayoutRoute exact path="/contratos" component={ContractsPage} />
      <AdminLayoutRoute exact path="/contratos/adicionar" component={ContractsAddPage} />
      <AdminLayoutRoute exact path="/contratos/alterar/:id" component={ContractsEditPage} />
      <AdminLayoutRoute exact path="/vendas" component={SalesPage} />
      <AdminLayoutRoute exact path="/vendas/adicionar" component={SalesAddPage} />
      <AdminLayoutRoute exact path="/vendas/alterar/:id" component={SalesEditPage} />
      <AdminLayoutRoute exact path="/orcamentos" component={BudgetsPage} />
      <AdminLayoutRoute exact path="/orcamentos/adicionar" component={BudgetsAddPage} />
      <AdminLayoutRoute exact path="/orcamentos/alterar/:id" component={BudgetsEditPage} />

      <Route exact path="/orcamentos/:id/pdf" component={BudgetsPDFPage} />
      <Route exact path="/vendas/:saleId/contrato/:contractId" component={SaleServiceContractPage} />

      <Redirect path="*" to="/dashboard" />
    </Switch>
  </BrowserRouter>
)
