import classNames from 'classnames'
import { Spinner } from 'components'

type ButtonProps = {
  type: 'button' | 'submit' | 'reset' | undefined
  className?: any
  isLoading?: boolean
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({ type = 'button', className, isLoading, children, ...props }) => {
  return (
    <button type={type} disabled={isLoading} className={classNames('button', className)} {...props}>
      {isLoading ? <Spinner /> : children}
    </button>
  )
}
