import { DateTime } from 'luxon'

import { ISale, IStoreSale, IStoreSaleForm, IUpdateSale, IUpdateSaleForm } from 'interfaces'
import { NumberHelper, SalePaymentMethodHelper } from 'helpers'

const renovationOptions = [
  { label: 'Renovar', value: 1 },
  { label: 'Não renovar', value: 0 },
]

const getRenovationByValue = (value: number) =>
  renovationOptions.find((renovation) => renovation.value === value) || null

const formatToDisplay = (saleList: ISale[]) =>
  saleList.map((sale) => ({
    ...sale,
    value: NumberHelper.formatToBRLText(sale.value),
  }))

const payloadToForm = (sale: ISale) => ({
  ...sale,
  value: NumberHelper.formatToBRL(sale.value),
})

const formToStorePayload = (form: IStoreSaleForm): IStoreSale => ({
  budgetId: form.budgetId.value.toString(),
  salePaymentMethods: form.salePaymentMethods.map((salePaymentMethod) => ({
    billingType: salePaymentMethod.billingType.value.toString(),
    installment: salePaymentMethod.installment,
    value: NumberHelper.formatToUSD(salePaymentMethod.value),
    dueAt: SalePaymentMethodHelper.dueAtFormatToPayload(salePaymentMethod.dueAt),
  })),
  saleContracts: form.saleContracts?.map((saleContract) => ({
    contractId: saleContract?.contractId?.value?.toString(),
  })),
})

const formToUpdatePayload = (form: IUpdateSaleForm): IUpdateSale => ({
  budgetId: form.budgetId.value.toString(),
  salePaymentMethods: form.salePaymentMethods.map((salePaymentMethod) => ({
    id: salePaymentMethod?.id,
    billingType: salePaymentMethod.billingType.value.toString(),
    saleId: salePaymentMethod.saleId,
    installment: salePaymentMethod.installment,
    dueAt: SalePaymentMethodHelper.dueAtFormatToPayload(salePaymentMethod.dueAt),
    value: NumberHelper.formatToUSD(salePaymentMethod.value),
  })),
  saleContracts: form.saleContracts?.map((saleContract) => ({
    id: saleContract?.id,
    contractId: saleContract?.contractId?.value?.toString(),
  })),
})

const formatWithInstallments = (sale: ISale) => ({
  ...sale,
  salePaymentMethods: sale.salePaymentMethods.map((salePaymentMethod) => ({
    ...salePaymentMethod,
    billingType: SalePaymentMethodHelper.findMethodByBillingType(salePaymentMethod.billingType)?.label || '',
    saleInstallments: salePaymentMethod.saleInstallments.map((saleInstallment) => ({
      ...saleInstallment,
      dueAt: DateTime.fromISO(saleInstallment.dueAt).setLocale('pt-BR').toFormat('D'),
      paidAt: saleInstallment.paidAt ? DateTime.fromISO(saleInstallment.paidAt).setLocale('pt-BR').toFormat('D') : '',
      updatedAt: DateTime.fromISO(saleInstallment.updatedAt).setLocale('pt-BR').toFormat('D H:m'),
    })),
  })),
})

const formatCreatedAt = (createdAt: string) => DateTime.fromISO(createdAt).toFormat('D', { locale: 'pt-BR' })

export const SaleHelper = {
  formatToDisplay,
  formToStorePayload,
  formToUpdatePayload,
  payloadToForm,
  renovationOptions,
  getRenovationByValue,
  formatWithInstallments,
  formatCreatedAt,
}
