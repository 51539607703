import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { ServicesServices } from 'services'
import { IFilterService, IPagination, IService, IStoreService } from 'interfaces'
import { ServiceHelper } from 'helpers'

export const useServices = () => {
  const [services, setServices] = useState<IPagination<IService>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllServices = useCallback(async (params?: IFilterService) => {
    const { status, data } = await ServicesServices.getAll(params)

    if (status === 200) {
      setServices({ ...data, items: ServiceHelper.formatToDisplay(data.items) })
    }
  }, [])

  const getServiceById = useCallback(async (id: string) => {
    const { status, data } = await ServicesServices.getById(id)

    if (status === 200) {
      return ServiceHelper.payloadToForm(data)
    }
  }, [])

  const createNewService = useCallback(async (service: IStoreService) => {
    const payload = ServiceHelper.formToStorePayload(service)
    const { status, data } = await toast.promise(ServicesServices.createNew(payload), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateServiceById = useCallback(async (id: string, service: IStoreService) => {
    const payload = ServiceHelper.formToStorePayload(service)
    const { status, data } = await toast.promise(ServicesServices.updateById(id, payload), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const deleteServiceById = useCallback(async (id: string) => {
    await toast.promise(ServicesServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Serviço excluído com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  return {
    services,
    getAllServices,
    getServiceById,
    createNewService,
    updateServiceById,
    deleteServiceById,
  }
}
