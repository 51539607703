import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateCompanySchema = yup
  .object({
    personType: yup.string().required('Informe se é Pessoa Jurídica ou Física'),
    companyType: yup
      .object({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
      })
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.object({
          label: yup.string().nullable(),
          value: yup.string().required('Selecione o tipo de empresa'),
        }),
        otherwise: yup
          .object({
            label: yup.string().nullable(),
            value: yup.string().nullable(),
          })
          .nullable(),
      }),
    fantasyName: yup.string().when('personType', {
      is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
      then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o nome fantasia'),
      otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
    }),
    socialName: yup.string().when('personType', {
      is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
      then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha a razão social'),
      otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
    }),
    cnpj: yup.string().when('personType', {
      is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
      then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CNPJ'),
      otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
    }),
    name: yup.string().when('personType', {
      is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
      then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha seu nome completo'),
      otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
    }),
    cpf: yup.string().when('personType', {
      is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
      then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CPF'),
      otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
    }),
    zipcode: yup.string().required('Informe o CEP'),
    state: yup.string().required('Informe o estado'),
    city: yup.string().required('Informe a cidade'),
    neighborhood: yup.string().required('Informe o bairro'),
    addressName: yup.string().required('Informe o endereço'),
    addressNumber: yup.string().required('Informe o número'),
    phone: yup.string().nullable(),
    cellphone: yup.string().required('Informe um celular'),
    backgroundColor: yup.string().nullable(),
  })
  .required()
export const CreateCompanyResolver = yupResolver(CreateCompanySchema)

const UpdateCompanySchema = yup
  .object({
    personType: yup.string().nullable().required('Informe se é Pessoa Jurídica ou Física'),
    companyType: yup
      .object({
        label: yup.string().nullable(),
        value: yup.string().nullable(),
      })
      .nullable()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.object({
          label: yup.string().nullable(),
          value: yup.string().nullable().required('Selecione o tipo de empresa'),
        }),
        otherwise: yup
          .object({
            label: yup.string().nullable(),
            value: yup.string().nullable(),
          })
          .nullable(),
      })
      .nullable(),
    fantasyName: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o nome fantasia'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    socialName: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha a razão social'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    cnpj: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'JURIDICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CNPJ'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    name: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha seu nome completo'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    cpf: yup
      .string()
      .when('personType', {
        is: (personType: string) => String(personType).toUpperCase() === 'FISICA',
        then: yup.string().max(255, 'Limite de 255 caracteres atingido').required('Preencha o CPF'),
        otherwise: yup.string().max(255, 'Limite de 255 caracteres atingido').nullable(),
      })
      .nullable(),
    zipcode: yup.string().nullable(),
    state: yup.string().nullable(),
    city: yup.string().nullable(),
    neighborhood: yup.string().nullable(),
    addressName: yup.string().nullable(),
    addressNumber: yup.string().nullable(),
    email: yup.string().nullable(),
    phone: yup.string().nullable(),
    cellphone: yup.string().nullable(),
    website: yup.string().nullable(),
    workHoursPerDay: yup.number().min(1, 'O valor deve ser maior que zero').nullable(),
    backgroundColor: yup.string().nullable(),
    companyStyles: yup.object({
      id: yup.string().nullable(),
      logoURL: yup.string().nullable(),
      textColor: yup.string().nullable(),
      primaryColor: yup.string().nullable(),
      backgroundColor: yup.string().nullable(),
    }),
    companyPaymentMethods: yup
      .object({
        id: yup.string(),
        boleto: yup.boolean().nullable(),
        debit: yup.boolean().nullable(),
        credit: yup.boolean().nullable(),
        ted: yup.boolean().nullable(),
        pix: yup.boolean().nullable(),
      })
      .nullable(),
  })
  .required()
export const UpdateCompanyResolver = yupResolver(UpdateCompanySchema)
