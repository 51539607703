import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import { SalesServices } from 'services'
import { IFilterSale, IPagination, ISale, IStoreSale, IUpdateSale, IUpdateSaleInstallment } from 'interfaces'
import { SaleHelper } from 'helpers'

export const useSales = () => {
  const [sale, setSale] = useState<ISale>()
  const [saleWithInstallments, setSaleWithInstallments] = useState<ISale>()
  const [sales, setSales] = useState<IPagination<ISale>>({
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    itemsTotal: 0,
    items: [],
  })

  const getAllSales = useCallback(async (params?: IFilterSale) => {
    const { status, data } = await SalesServices.getAll(params)

    if (status === 200) {
      setSales({ ...data, items: SaleHelper.formatToDisplay(data.items) })
    }
  }, [])

  const getSaleById = useCallback(async (id: string) => {
    const { status, data } = await SalesServices.getById(id)

    if (status === 200) {
      setSale(data)
      return data
    }
  }, [])

  const createNewSale = useCallback(async (sale: IStoreSale) => {
    const { status, data } = await toast.promise(SalesServices.createNew(sale), {
      pending: 'Cadastrando, por favor, aguarde...',
      success: 'Cadastro realizado com sucesso!',
      error: 'Houve um erro inesperado ao cadastrar!',
    })

    if (status === 201) {
      return data
    }
  }, [])

  const updateSaleById = useCallback(async (id: string, sale: IUpdateSale) => {
    const { status, data } = await toast.promise(SalesServices.updateById(id, sale), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Cadastro atualizado com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })

    if (status === 200) {
      return data
    }
  }, [])

  const getContract = useCallback(async (saleId: string, contractId: string) => {
    const { status, data } = await SalesServices.showContract(saleId, contractId)
    if (status === 200) {
      return data
    }
  }, [])

  const getInstallmentsById = useCallback(async (id: string) => {
    const { status, data } = await SalesServices.showInstallments(id)

    if (status === 200) {
      setSaleWithInstallments(SaleHelper.formatWithInstallments(data))
    }
  }, [])

  const deleteSaleById = useCallback(async (id: string) => {
    await toast.promise(SalesServices.deleteById(id), {
      pending: 'Excluindo, por favor, aguarde...',
      success: 'Cadastro excluído com sucesso!',
      error: 'Houve um erro inesperado ao excluir!',
    })
  }, [])

  const updateSaleInstallmentById = useCallback(async (installmentId: string, data: IUpdateSaleInstallment) => {
    await toast.promise(SalesServices.updateSaleInstallmentById(installmentId, data), {
      pending: 'Atualizando, por favor, aguarde...',
      success: 'Parcela atualizada com sucesso!',
      error: 'Houve um erro inesperado ao atualizar!',
    })
  }, [])

  return {
    sale,
    saleWithInstallments,
    sales,
    getAllSales,
    getSaleById,
    createNewSale,
    updateSaleById,
    deleteSaleById,
    getContract,
    getInstallmentsById,
    updateSaleInstallmentById,
  }
}
