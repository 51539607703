import { Link, useHistory } from 'react-router-dom'
import { FaBuilding, FaUserFriends, FaPaperclip, FaHammer, FaPenFancy, FaCashRegister, FaTrello } from 'react-icons/fa'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

import { useSidebarContext } from 'contexts/SidebarContext'
import { useCallback } from 'react'

export type SidebarProps = {
  isVisible: boolean
}

export const Sidebar: React.FC<SidebarProps> = ({ isVisible }) => {
  const { setIsVisible } = useSidebarContext()
  const history = useHistory()
  const match = (path: string) => history.location.pathname.startsWith(path)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const handleOnClick = useCallback(() => {
    if (isTabletOrMobile) {
      setIsVisible(false)
    }
  }, [isTabletOrMobile, setIsVisible])

  return (
    <aside
      className={classNames(
        'w-full max-w-sm min-h-full bg-white p-4 filter drop-shadow-md transition-all ease-in-out duration-300 absolute top-0 left-0',
        {
          '-left-full': !isVisible,
        }
      )}
    >
      <ul>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/empresas') })}
            to="/empresas"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaBuilding color="#333" size="1.1rem" className="mr-2" />
            Empresas
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/boards') })}
            to="/boards"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaTrello color="#333" size="1.1rem" className="mr-2" />
            Boards
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/clientes') })}
            to="/clientes"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaUserFriends color="#333" size="1.1rem" className="mr-2" />
            Clientes
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/orcamentos') })}
            to="/orcamentos"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaPaperclip color="#333" size="1.1rem" className="mr-2" />
            Orçamentos
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/servicos') })}
            to="/servicos"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaHammer color="#333" size="1.1rem" className="mr-2" />
            Serviços
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/contratos') })}
            to="/contratos"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaPenFancy color="#333" size="1.1rem" className="mr-2" />
            Contratos
          </Link>
        </li>
        <li>
          <Link
            onClick={handleOnClick}
            className={classNames('sidebar-item', { 'sidebar-item--active': match('/vendas') })}
            to="/vendas"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <FaCashRegister color="#333" size="1.1rem" className="mr-2" />
            Vendas
          </Link>
        </li>
      </ul>
    </aside>
  )
}
