import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { DateTime } from 'luxon-business-days'

import { Breadcrumbs, Button, DatePicker, Input, Select } from 'components'
import { useBoards, useBudgets } from 'hooks'
import { CreateBoardsResolver } from 'validations'
import { BudgetHelper } from 'helpers'
import { IStoreBoardForm } from 'interfaces'

export const BoardsAddPage = () => {
  const history = useHistory()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm({
    resolver: CreateBoardsResolver,
    defaultValues: {
      dueAt: DateTime.now().plusBusiness({ days: 14 }).toJSDate(),
    },
  })

  const { createNewBoard } = useBoards()
  const { budgets, getAllBudgets, getAllBudgetsOptionsDebounced } = useBudgets()

  const onSubmit = async (value: IStoreBoardForm) => {
    const response = await createNewBoard(value)
    if (response) {
      history.push('/boards')
    }
  }

  const budgetOptions = useMemo(() => BudgetHelper.formatToOptions(budgets?.items), [budgets?.items])

  useEffect(() => {
    getAllBudgets({ billed: 1 })
  }, [getAllBudgets])

  return (
    <div className="p-8">
      <Breadcrumbs
        links={[
          { label: 'Dashboard', href: '/dashboard' },
          { label: 'Boards', href: '/boards' },
          { label: 'Adicionar' },
        ]}
      />

      <div className="box p-8 w-full">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="mb-8">Adicionar Board</h1>

          <div className="mb-4">
            <Select
              async
              defaultOptions={budgetOptions}
              loadOptions={(search: string, callback) => {
                getAllBudgetsOptionsDebounced(callback, { billed: 1, search })
              }}
              control={control}
              name="budgetId"
              placeholder="Orçamento"
            />
          </div>

          <div className="sm:grid gap-2 grid-cols-2 mb-4">
            <Input control={control} name="name" label="Nome" />
            <DatePicker control={control} name="dueAt" label="Data de Vencimento" />
          </div>

          <div className="w-full flex flex-row pt-4 justify-end">
            <Button className="button--primary" type="submit" isLoading={isSubmitting}>
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
