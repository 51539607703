import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'

import { Button, Input, RadioGroup, Select } from 'components'
import { useBrasilAPI, useCompanies } from 'hooks'
import { CreateCompanyResolver } from 'validations'
import { CompanyHelper } from 'helpers'

export const SignUpCompany = () => {
  const [isZipcodeLoading, setIsZipcodeLoading] = useState(false)
  const [isCompanyLoading, setIsCompanyLoading] = useState(false)

  const {
    address,
    company: companyFromCNPJ,
    getAddressByZipcodeDebounced,
    getCompanyInfoByCNPJDebounced,
  } = useBrasilAPI()

  const {
    control,
    formState: { isSubmitting },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ resolver: CreateCompanyResolver, defaultValues: { personType: 'JURIDICA' } })

  const { createNewCompanies } = useCompanies()
  const history = useHistory()

  const cnpjWatched = watch('cnpj')
  const zipcodeWatched = watch('zipcode')
  const personTypeWatched = watch('personType')

  const onSubmit = useCallback(
    async (data) => {
      console.log(JSON.stringify(data, null, 2))

      const payload = CompanyHelper.formToStorePayload(data)
      const response = await createNewCompanies(payload)
      if (response) {
        history.push('/entrar')
      }
    },
    [createNewCompanies, history]
  )

  const searchCompanyAddressByZipcode = useCallback(() => {
    if (zipcodeWatched) {
      const zipcodeStripped = String(zipcodeWatched)
      if (zipcodeStripped?.length >= 8) {
        setIsZipcodeLoading(true)
        getAddressByZipcodeDebounced(String(zipcodeWatched))
        setTimeout(() => {
          setIsZipcodeLoading(false)
        }, 5000)
      }
    }
  }, [getAddressByZipcodeDebounced, zipcodeWatched])

  const searchCompanyByCNPJ = useCallback(() => {
    if (cnpjWatched && cnpjWatched?.length >= 18) {
      setIsCompanyLoading(true)
      getCompanyInfoByCNPJDebounced(cnpjWatched)
      setTimeout(() => {
        setIsCompanyLoading(false)
      }, 5000)
    }
  }, [getCompanyInfoByCNPJDebounced, cnpjWatched])

  useEffect(() => {
    if (address) {
      setValue('state', address.state)
      setValue('city', address.city)
      setValue('neighborhood', address.neighborhood)
      setValue('addressName', address.street)
    }
    setIsZipcodeLoading(false)
  }, [address, setValue])

  useEffect(() => {
    if (companyFromCNPJ) {
      setValue('socialName', companyFromCNPJ.razao_social)
      setValue('fantasyName', companyFromCNPJ.nome_fantasia || companyFromCNPJ.razao_social)
      setValue('zipcode', companyFromCNPJ.cep)

      let phone = companyFromCNPJ.ddd_fax || companyFromCNPJ.ddd_telefone_1 || companyFromCNPJ.ddd_telefone_2
      phone = String(phone).replace(/ /g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')
      setValue('phone', phone)
    }
    setIsCompanyLoading(false)
  }, [companyFromCNPJ, setValue])

  return (
    <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup
        label="Tipo"
        name="personType"
        control={control}
        items={[
          { id: 'juridica', label: 'Pessoa Jurídica', value: 'JURIDICA' },
          { id: 'fisica', label: 'Pessoa Física', value: 'FISICA' },
        ]}
      />

      {personTypeWatched === 'JURIDICA' ? (
        <>
          <div className="sm:grid gap-2 grid-cols-2">
            <Input
              control={control}
              name="cnpj"
              label="CNPJ"
              placeholder="CNPJ"
              mask="99.999.999/9999-99"
              AppendComponent={
                <Button
                  isLoading={isCompanyLoading}
                  className="button--light ml-2"
                  type="button"
                  onClick={searchCompanyByCNPJ}
                >
                  <FaSearch />
                </Button>
              }
            />
            <Select
              control={control}
              name="companyType"
              options={CompanyHelper.bussinesTypesOptions}
              label="Tipo de Empresa"
            />
          </div>
          <div className="sm:grid gap-2 grid-cols-2">
            <Input control={control} name="socialName" label="Razão social" />
            <Input control={control} name="fantasyName" label="Nome fantasia" />
          </div>
        </>
      ) : (
        <div className="sm:grid gap-2 grid-cols-2">
          <Input control={control} name="name" placeholder="Nome completo" />
          <Input control={control} name="cpf" placeholder="CPF" mask="999.999.999-99" />
        </div>
      )}

      <div className="sm:grid gap-2 grid-cols-2">
        <Input
          control={control}
          name="zipcode"
          placeholder="CEP"
          mask="99999-999"
          AppendComponent={
            <Button
              isLoading={isZipcodeLoading}
              className="button--light ml-2"
              type="button"
              onClick={searchCompanyAddressByZipcode}
            >
              <FaSearch />
            </Button>
          }
        />
      </div>
      <div className="grid gap-2 grid-cols-2">
        <Input control={control} name="addressName" label="Endereço" />
        <Input control={control} name="addressNumber" label="Número" />
      </div>
      <div className="sm:grid gap-2 grid-cols-3">
        <Input control={control} name="neighborhood" placeholder="Bairro" />
        <Input control={control} name="city" placeholder="Cidade" />
        <Input control={control} name="state" placeholder="UF" />
      </div>
      <div className="grid gap-2 grid-cols-2">
        <Input control={control} name="cellphone" placeholder="Celular" mask="(99) 99999-9999" />
        <Input control={control} name="phone" placeholder="Telefone" mask="(99) 9999-9999" />
      </div>

      <div className="w-full flex flex-row items-center justify-end py-4">
        <Button className="button--primary" type="submit" isLoading={isSubmitting}>
          Criar conta
        </Button>
      </div>
    </form>
  )
}
