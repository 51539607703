import { AxiosResponse } from 'axios'

import { IBudget, IFilterBudget, IPagination, IStoreBudget, IUpdateBudget } from 'interfaces'
import { api } from 'providers'

const getAll = (params?: IFilterBudget) => api.get<IPagination<IBudget>>('/api/v1/budgets', { params })

const getById = (id: string) => api.get<IBudget>(`/api/v1/budgets/${id}`)

const createNew = (budget: IStoreBudget) => api.post<IStoreBudget, AxiosResponse<IBudget>>('/api/v1/budgets', budget)

const updateById = (id: string, budget: IUpdateBudget) =>
  api.patch<IUpdateBudget, AxiosResponse<IBudget>>(`/api/v1/budgets/${id}`, budget)

const deleteById = (id: string) => api.delete(`/api/v1/budgets/${id}`)

const getPDF = (id: string) => api.get<string>(`/api/v1/budgets/${id}/pdf`)

export const BudgetServices = {
  getAll,
  createNew,
  updateById,
  getById,
  deleteById,
  getPDF
}
