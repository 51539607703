import { useController } from 'react-hook-form'
import get from 'lodash.get'

type TextAreaProps = {
  autoFocus?: boolean
  name: string
  showLabel?: boolean
  label?: string
  placeholder?: string
  control: any
  rows?: number
}

export const TextArea: React.FC<TextAreaProps> = ({
  autoFocus = false,
  showLabel = true,
  name,
  label,
  placeholder,
  control,
  rows = 10,
}) => {
  const {
    field,
    formState: { errors },
  } = useController({ control, name })

  const errorMessage = get(errors, `${name}.message`)

  return (
    <div className="block w-full">
      {showLabel && (
        <label htmlFor={name} className="input-label">
          {label || placeholder}
        </label>
      )}

      <textarea
        {...field}
        id={name}
        placeholder={placeholder || label}
        className="input"
        rows={rows}
        autoFocus={autoFocus}
      />

      {errorMessage && <p className="input-text-error">{errorMessage}</p>}
    </div>
  )
}
